import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { getTeamById } from 'redux/actions/teams';

import { Fade, Tooltip } from '@mui/material';
import theme from 'theme/theme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { ITeams } from 'types/api/teams';
import { prevPageUrlStorage, selectedTeamStorage } from '../../../helpers/storageFunctions';
import { getCurrentUser } from '../../../redux/selectors/auth';
import LogoutModal from '../../modal/logout-modal/';
import UserProfileMenu from '../user-profile-menu';

import { routesWithBackBtnOnPage } from 'assets/routes';
import TeamMenu from '../user-team-menu';
import UserTeamsSelector from '../user-teams-selector';

import useStyles from './style';

type PopoverContentProps = {
  closePopover: (event: React.MouseEvent<any>) => void;
};

const PopoverContent: React.FC<PopoverContentProps> = ({ closePopover }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [activeTeamName, setActiveTeamName] = useState(selectedTeamStorage().getSelectedTeam()?.name);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  useEffect(() => {
    setActiveTeamName(selectedTeamStorage().getSelectedTeam()?.name);
  }, [currentUser]);

  useEffect(() => {
    const pathname = window.location.pathname;
    const isRouteWithBackBtnOnPage = routesWithBackBtnOnPage.includes(pathname);
    !isRouteWithBackBtnOnPage && prevPageUrlStorage().setPrevPageUrl([pathname]);
  }, [history.location.pathname]);

  const handleLogoutClick = () => {
    setOpenLogoutDialog(!openLogoutDialog);
  };

  const setActiveTeam = (team: ITeams) => {
    const currentUserInTeam = team.employees.find((user) => user._id === currentUser._id);
    const teamWithCurrentTeamRole = {
      ...team,
      teamRole: currentUserInTeam?.role,
    };
    selectedTeamStorage().setSelectedTeam(teamWithCurrentTeamRole);
    setActiveTeamName(team.name);
    history.push(location.pathname);

    dispatch(getTeamById({}));
  };

  return (
    <>
      <div className={classes.popoverContainer}>
        <div className={classes.popoverContentContainer}>
          <UserTeamsSelector
            userTeams={currentUser.teams}
            setActiveTeam={setActiveTeam}
            activeTeamName={activeTeamName}
          />
          <Link to='/team/create'>
            <Tooltip
              title={t('createTeamTooltipText')}
              placement='right-end'
              disableFocusListener
              TransitionComponent={Fade}
              slotProps={{
                tooltip: {
                  className: classes.tooltip,
                  style: { color: theme.palette.primary.main },
                },
              }}
            >
              <div className={classes.addTeamBtn} style={!currentUser.teams.length ? { marginTop: 0 } : {}}>
                <AddRoundedIcon color='primary' width='24px' height='24px' onClick={(event) => closePopover(event)} />
              </div>
            </Tooltip>
          </Link>
        </div>
        {activeTeamName && <TeamMenu currentUser={currentUser} teamName={activeTeamName} closePopover={closePopover} />}
        <UserProfileMenu currentUser={currentUser} handleLogoutClick={handleLogoutClick} closePopover={closePopover} />
      </div>
      <LogoutModal isOpen={openLogoutDialog} toggleDialog={handleLogoutClick} />
    </>
  );
};

export default PopoverContent;
