import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

import theme from 'theme/theme';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as DeleteIcon } from '../../../svg/teamProfileSection/delete.svg';
import { IconButton, Typography, useMediaQuery } from '@mui/material';

import Adornment from 'shared/input/adornment';
import useStyles from './style';

type MemberEmailsInputProps = {
  createInput: (params: {
    name: string;
    label: string;
    type: string;
    helperText?: string;
    helperTextStyle?: string;
    formControlStyle?: string;
    disabled?: boolean;
    keyDownPressHandle?: (event: React.KeyboardEvent) => void;
    endAdornment?: JSX.Element;
    isReset?: boolean;
    setIsReset?: Dispatch<SetStateAction<boolean>>;
  }) => JSX.Element;
  membersEmails: string[];
  setMembersEmails: React.Dispatch<React.SetStateAction<string[]>>;
  formik: FormikProps<any>;
  isInviteModal: boolean;
  isOutReset?: boolean;
  setIsOutReset?: Dispatch<SetStateAction<boolean>>;
  dicisionMakerModal?: boolean;
  extraFieldStyles?: string;
};

const MemberEmailsInput: React.FC<MemberEmailsInputProps> = ({
  createInput,
  membersEmails,
  setMembersEmails,
  formik,
  isInviteModal,
  extraFieldStyles,
  isOutReset,
  setIsOutReset,
  dicisionMakerModal = false,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isMemberEmailError =
    formik.errors.memberEmail &&
    formik.errors.memberEmail !== (t('emptyEmailsError') as string) &&
    formik.touched.memberEmail;

  const [isReset, setIsReset] = useState(false);

  const addMemberEmailOnEnter = (event: React.KeyboardEvent) => {
    const memberEmail = formik.values.memberEmail;

    if (event.key === 'Enter' && memberEmail) {
      if ((membersEmails.includes(memberEmail) || isMemberEmailError) && !membersEmails.length) {
        formik.setTouched({ memberEmail: true }, true);
      } else {
        setMembersEmails((prevState) => [...prevState, memberEmail]);
        formik.setFieldValue('memberEmail', '');
      }
    }
  };

  const addMemberEmail = () => {
    const memberEmail = formik.values.memberEmail;

    if (memberEmail) {
      setMembersEmails((prevState) => [...prevState, memberEmail]);
      setIsReset(true);
    }
  };

  const removeMemberEmail = (emailIndex: number) => {
    setMembersEmails((prevMembersEmails) => prevMembersEmails.filter((_, index) => index !== emailIndex));
  };

  return (
    <>
      {isMobile || isInviteModal ? (
        <div className={extraFieldStyles ? extraFieldStyles : ''}>
          {createInput({
            name: 'memberEmail',
            label: t('membersEmailsInputLabel'),
            type: 'email',
            helperText: !membersEmails.length ? ' ' : '',
            helperTextStyle: classes.invitePeopleInputHelperText,
            formControlStyle: classes.invitePeopleFormControl,
            keyDownPressHandle: addMemberEmailOnEnter,
            endAdornment: !dicisionMakerModal ? (
              <Adornment
                handleClickShowValue={!isMemberEmailError ? addMemberEmail : () => {}}
                position={'end'}
                mainIcon={<AddRoundedIcon color={isMemberEmailError ? 'error' : 'primary'} />}
              />
            ) : undefined,
            isReset: isOutReset ? isOutReset : isReset,
            setIsReset: isOutReset ? setIsOutReset : setIsReset,
          })}
        </div>
      ) : (
        <div className={extraFieldStyles ? extraFieldStyles : ''}>
          {createInput({
            name: 'memberEmail',
            label: t('membersEmailsInputLabel'),
            type: 'email',
            helperText: t('membersEmailsInputHelperText') as string,
            helperTextStyle: classes.invitePeopleInputHelperText,
            formControlStyle: classes.invitePeopleFormControl,
            disabled: true,
            keyDownPressHandle: addMemberEmailOnEnter,
          })}
        </div>
      )}
      {!dicisionMakerModal && (
        <div>
          {membersEmails.map((membersEmail, index) => (
            <div key={membersEmail} className={classes.invitedMail}>
              <CheckRoundedIcon className={classes.addedEmail} />
              <Typography>{membersEmail}</Typography>
              <IconButton onClick={() => removeMemberEmail(index)} className={classes.remove}>
                <DeleteIcon stroke={theme.palette.secondary.contrastText} width={18} height={18} />
              </IconButton>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MemberEmailsInput;
