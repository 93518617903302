import { store } from '../../../index';
import { ICandidateHistory } from 'types/api/candidates';
import { PayloadAction } from '@reduxjs/toolkit';
import { getCommentsForCandidate } from 'redux/actions/candidateComments';
import { getHistoryByCandidate } from 'redux/actions/history';

export function* onHireCandidate({ payload }: PayloadAction<ICandidateHistory>) {
  const { dispatch } = store;
  dispatch(getCommentsForCandidate(payload?.candidate as string));
  dispatch(getHistoryByCandidate(payload?.candidate as string));
  yield true;
}
