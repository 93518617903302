import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isSidebarOpen: boolean }>()((theme, { isSidebarOpen }) => {
  return {
    navButton: {
      color: '#DADADA',
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      transition: 'color 150ms linear',
      fontSize: '1.5rem',
      fontWeight: 400,

      '&:hover': {
        color: '#FDFDFD',
        transition: 'all 150ms linear',
      },
    },
    navButtonTextContainer: {
      display: isSidebarOpen ? 'block' : 'none',
      marginLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1rem',
      [theme.breakpoints.down('mobile')]: {
        fontSize: '1.75rem',
        display: 'block',
      },
    },
    accordeonTextContainer: {
      fontSize: '1rem',

      [theme.breakpoints.down('mobile')]: {
        fontSize: '1.5rem',
      },
    },
    currentPage: {
      color: '#FDFDFD',
      fontWeight: 700,
    },
    accordeonCurrentPage: {
      color: '#FDFDFD',
      fontWeight: 500,
    },
    iconContainer: {
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.down('mobile')]: {
        height: '36px',
        width: '36px',
      },
    },
    accordeonIconContainer: {
      width: '20px',
      height: '20px',

      [theme.breakpoints.down('mobile')]: {
        height: '32px',
        width: '32px',
      },
    },
    thing: {
      width: '100px',
    },
  };
});

export default useStyles;
