import { takeLatest } from 'redux-saga/effects';

import { CREATE_TAGS_SUCCESS, DELETE_TAG_SUCCESS, UPDATE_TAG_SUCCESS } from '../../../actions/tags';

import { onGetTags } from './onGetTags';

export default function* fn() {
  yield takeLatest(CREATE_TAGS_SUCCESS.type, onGetTags);
  yield takeLatest(UPDATE_TAG_SUCCESS.type, onGetTags);
  yield takeLatest(DELETE_TAG_SUCCESS.type, onGetTags);
}
