import { takeLatest } from 'redux-saga/effects';

import {
  ADD_DOCUMENT_SUCCESS,
  CHANGE_USER_INFO_SUCCESS,
  CREATE_USER_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_DOCUMENT_SUCCESS,
  UPLOAD_AVATAR_SUCCESS,
  DELETE_AVATAR_SUCCESS,
} from '../../../actions/users';

import onCreate from './onCreate';
import changeUserParameter from './onChangeParametr';
import onUploadFilesSuccess from './onUploadFilesSuccess';
import { GET_CURRENT_USER_SUCCESS } from 'redux/actions/auth';
import onGetCurrentUser from './onGetCurrentUser';
import onChangeUser from './onChangeUser';

export default function* fn() {
  yield takeLatest(GET_CURRENT_USER_SUCCESS, onGetCurrentUser);
  yield takeLatest(CREATE_USER_SUCCESS, onCreate);
  yield takeLatest(DELETE_USER_SUCCESS, changeUserParameter);
  yield takeLatest(UPLOAD_AVATAR_SUCCESS, onUploadFilesSuccess);
  yield takeLatest(DELETE_AVATAR_SUCCESS, onUploadFilesSuccess);
  yield takeLatest(ADD_DOCUMENT_SUCCESS, onUploadFilesSuccess);
  yield takeLatest(DELETE_DOCUMENT_SUCCESS, onUploadFilesSuccess);
  yield takeLatest(EDIT_DOCUMENT_SUCCESS, onUploadFilesSuccess);
  yield takeLatest(CHANGE_USER_INFO_SUCCESS, onChangeUser);
}
