import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';
import { getErrorMessage } from '../../helpers/getErrorMessage';

export const GET_ALL_SPECIALIZATIONS_SUCCESS = createAction('specializations/GET_ALL_SPECIALIZATIONS.SUCCESS');
export const getAllSpecializations = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({ method: 'GET', endpoint: `specializations` });
    dispatch(GET_ALL_SPECIALIZATIONS_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_SPECIALIZATIONS_SUCCESS = createAction('specializations/CREATE_SPECIALIZATIONS.SUCCESS');
export const CREATE_SPECIALIZATIONS_FAILURE = createAction('specializations/CREATE_SPECIALIZATIONS.FAILURE');
export const createSpecializations =
  (specializations: string[], onSuccess: () => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `specializations`,
        query: specializations,
      });
      dispatch(CREATE_SPECIALIZATIONS_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      dispatch(CREATE_SPECIALIZATIONS_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const UPDATE_SPECIALIZATION_SUCCESS = createAction('specializations/UPDATE_SPECIALIZATION.SUCCESS');
export const UPDATE_SPECIALIZATION_FAILURE = createAction('specializations/UPDATE_SPECIALIZATION.FAILURE');
export const updateSpecialization =
  (specializationID: string, specialization: string, onSuccess: () => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `specializations/${specializationID}`,
        query: { specialization },
      });
      dispatch(UPDATE_SPECIALIZATION_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      dispatch(UPDATE_SPECIALIZATION_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const DELETE_SPECIALIZATION_SUCCESS = createAction('specializations/DELETE_SPECIALIZATION.SUCCESS');
export const DELETE_SPECIALIZATION_FAILURE = createAction('specializations/DELETE_SPECIALIZATION.FAILURE');
export const deleteSpecialization =
  (specializationID: string, onSuccess: () => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `specializations/${specializationID}`,
      });
      dispatch(DELETE_SPECIALIZATION_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      dispatch(DELETE_SPECIALIZATION_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };
