import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    avatar: {
      width: '40px',
      height: '40px',
      fontSize: '1rem',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      backgroundColor: theme.palette.primary.main,
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px',
      borderLeft: '1px solid #DADADA',
      alignSelf: 'stretch',

      [theme.breakpoints.down('mobile')]: {
        borderLeft: 'none',
      },
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    teamName: {
      fontWeight: 600,
      marginLeft: '16px',
    },
    divider: {
      marginTop: '16px',
    },
    navLink: {
      marginTop: '4px',
      border: `8px solid transparent`,
      minWidth: '235px',
      cursor: 'pointer',
      transition: 'all 150ms linear',

      '&:hover': {
        backgroundColor: 'rgba(69, 43, 228, 0.15)',
        transition: 'all 150ms linear',
      },
    },
    disabledNavLink: {
      marginTop: '4px',
      border: `8px solid transparent`,
      minWidth: '235px',
      opacity: 0.5,
      pointerEvents: 'none',

      [theme.breakpoints.down('mobile')]: {
        display: 'none',
      },
    },
  };
});

export default useStyles;
