import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    buttonsGroup: {
      marginBottom: '8px',
      width: '100%',
      flexWrap: 'wrap',
      '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderLeft: '1px solid #E2E2E2',
        marginLeft: 0,
        transition: 'all 150ms linear',

        '&.Mui-selected': {
          borderLeft: '1px solid #3F51B5',
        },

        '&:hover': {
          borderLeft: '1px solid #DDE7FA',
          transition: 'all 150ms linear',
        },

        [theme.breakpoints.down('mobile')]: {
          '&:hover': {
            borderLeft: '1px solid #DDE7FA',
          },
          '&.Mui-selected': {
            borderLeft: '1px solid #3F51B5',
          },
        },
      },

      [theme.breakpoints.down('mobile')]: {
        marginBottom: '24px',
      },
    },
    button: {
      marginBottom: '16px',
      marginRight: '16px',
      padding: '8px 12px',
      border: '1px solid #E2E2E2',
      borderRadius: '4px',
      textTransform: 'inherit',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      transition: 'all 150ms linear',

      '&.Mui-selected': {
        border: '1px solid #3F51B5',
        backgroundColor: '#DDE7FA',
        color: '#3F51B5',
      },

      '&.Mui-selected:hover': {
        backgroundColor: '#FDFDFD',
        border: '1px solid #DDE7FA',
        color: '#3F51B5',
        transition: 'all 150ms linear',
      },

      '&:hover': {
        border: '1px solid #DDE7FA',
        backgroundColor: '#FDFDFD',
        color: '#3F51B5',
        transition: 'all 150ms linear',
      },

      [theme.breakpoints.down('mobile')]: {
        '&.Mui-selected:hover': {
          backgroundColor: '#DDE7FA',
          border: '1px solid #3F51B5',
          color: '#3F51B5',
        },

        '&:hover': {
          border: '1px solid #DDE7FA',
          backgroundColor: 'unset',
          color: 'unset',
        },
      },
    },
    buttonText: {
      [theme.breakpoints.down('mobile')]: {
        fontSize: '0.875rem',
      },
    },
    errorButtonGroup: {
      '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderLeft: `1px solid ${theme.palette.error.dark}`,

        [theme.breakpoints.down('mobile')]: {
          '&:hover': {
            borderLeft: `1px solid ${theme.palette.error.dark}`,
          },
        },
      },
    },
    errorButton: {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.dark}`,

      [theme.breakpoints.down('mobile')]: {
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.error.light,
        },

        '&:hover': {
          border: `1px solid ${theme.palette.error.dark}`,
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.dark,
        },
      },
    },
    errorContainer: {
      margin: '0 14px',
      textAlign: 'left',

      [theme.breakpoints.down('mobile')]: {
        marginLeft: '3px',
      },
    },
    error: {
      fontSize: '0.875rem',
      color: theme.palette.error.main,

      [theme.breakpoints.down('mobile')]: {
        fontSize: '0.6rem',
      },
    },
  };
});

export default useStyles;
