import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import { GET_ALL_TAGS_SUCCESS } from '../actions/tags';
import { ITags } from 'types/api/tags';

type InitialState = {
  tags: ITags[];
};

const initialState: InitialState = {
  tags: [],
};

const handlers = {
  [GET_ALL_TAGS_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<ITags[]>) => {
    return {
      ...prevState,
      tags: payload,
    };
  },
};

export default createReducer(initialState, handlers);
