import { takeLatest } from 'redux-saga/effects';

import { CHANGE_CANDIDATE_STATUS_SUCCESS } from '../../../actions/candidates';
import { UPDATE_CANDIDATE_SUCCESS, REJECT_CANDIDATE_SUCCESS, HIRE_CANDIDATE_SUCCESS } from 'redux/actions/candidates';

import { onChangeCandidateStatus } from './onChangeCandidateStatus';
import { onUpdateCandidate } from './onUpdateCandidate';
import { onRejectCandidate } from './onRejectCandidate';
import { onHireCandidate } from './onHireCandidate';

export default function* fn() {
  yield takeLatest(CHANGE_CANDIDATE_STATUS_SUCCESS.type, onChangeCandidateStatus);
  yield takeLatest(UPDATE_CANDIDATE_SUCCESS.type, onUpdateCandidate);
  yield takeLatest(REJECT_CANDIDATE_SUCCESS.type, onRejectCandidate);
  yield takeLatest(HIRE_CANDIDATE_SUCCESS.type, onHireCandidate);
}
