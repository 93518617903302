import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_CANDIDATES_HISTORY_BY_ID_SUCCESS = createAction('users/GET_CANDIDATES_HISTORY_BY_ID.SUCCESS');
export const getHistoryByCandidate = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `candidates-history/candidate/${userId}`,
      method: 'GET',
    });
    dispatch(GET_CANDIDATES_HISTORY_BY_ID_SUCCESS(data));
  } catch (err) {}
};

export const CLEAR_CANDIDATE_HISTORY = createAction('candidates/CLEAR_CANDIDATE_HISTORY');
export const clearCandidateHistory = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_CANDIDATE_HISTORY());
};
