import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import users from './users';
import candidateComments from './candidateComments';
import userReviews from './userReviews';
import candidateReminders from './candidateReminders';
import teams from './teams';
import vacancies from './vacancies';
import specializations from './specializations';
import channels from './channels';
import statistics from './statistics';
import filters from './filters';
import history from './history';
import tags from './tags';
import clients from './clients';
import candidates from './candidates';

const rootReducer = combineReducers({
  auth,
  users,
  candidateComments,
  userReviews,
  candidateReminders,
  teams,
  candidates,
  vacancies,
  specializations,
  channels,
  statistics,
  filters,
  history,
  clients,
  tags,
});

export default rootReducer;
