import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    invitePeopleInputHelperText: {
      textAlign: 'left',
      fontSize: '0.875rem',
    },
    invitePeopleFormControl: {
      marginBottom: 0,
    },
    addedEmail: {
      color: '#71D86A',
      fontSize: '1.5rem',
      marginRight: '8px',
    },
    invitedMail: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '6px',
      '&:last-child': {
        marginBottom: '16px',
      },
    },
    remove: {
      marginLeft: 'auto',
    },
  };
});

export default useStyles;
