export const profileRoute = ['/profile/:type'];

export const commonRoutes = [
  '/candidates/board',
  '/candidates/:type',
  '/candidates/:id/:type',
  '/user/:id/:type',
  '/team/create',
  '/team/profile',
  '/team/rename',
  '/profiles-navigation',
  '/candidates/create',
  '/candidate/:id',
  '/clients',
  '/clients/:id/edit',
  '/reminders',
  ...profileRoute,
];

export const routesWithBackBtnOnPage = ['/profiles-navigation'];
export const publicRoutes = ['/signup', '/signin', '/resetpassword', '/reset-password/:token'];
