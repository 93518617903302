import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_USER_REVIEW_SUCCESS,
  DELETE_USER_REVIEW_SUCCESS,
  UPDATE_USER_REVIEW_SUCCESS,
} from '../../../actions/userReviews';
import { onGetReviewsForUser } from './onGetReviewsForUser';

export default function* fn() {
  yield takeLatest(CREATE_USER_REVIEW_SUCCESS, onGetReviewsForUser);
  yield takeLatest(UPDATE_USER_REVIEW_SUCCESS, onGetReviewsForUser);
  yield takeLatest(DELETE_USER_REVIEW_SUCCESS, onGetReviewsForUser);
}
