import { takeLatest } from 'redux-saga/effects';

import { CHANGE_VACANCY_STATUS_SUCCESS, COPY_VACANCY_SUCCES, DELETE_VACANCY_SUCCESS } from '../../../actions/vacancies';

import { onChangeVacancyStatus } from './onChangeVacancyStatus';

export default function* fn() {
  yield takeLatest(CHANGE_VACANCY_STATUS_SUCCESS.type, onChangeVacancyStatus);
  yield takeLatest(COPY_VACANCY_SUCCES.type, onChangeVacancyStatus);
  yield takeLatest(DELETE_VACANCY_SUCCESS.type, onChangeVacancyStatus);
}
