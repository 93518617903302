import { fetchCurrentUser } from 'redux/actions/auth';
import { store } from '../../../index';

function* onChangeUser() {
  const { dispatch } = store;
  dispatch(fetchCurrentUser());

  yield true;
}

export default onChangeUser;
