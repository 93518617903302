import i18next from 'i18next';
import theme from 'theme/theme';

export enum VacancyStatusEnum {
  OPEN = 'OPEN',
  EDIT = 'EDIT',
  CLOSE_SUCCESSFUL = 'CLOSE_SUCCESSFUL',
  CLOSE_UNSUCCESSFUL = 'CLOSE_UNSUCCESSFUL',
  CANCELLED = 'CANCELLED',
  TEMPLATE = 'TEMPLATE',
}

export type VacancyStatus = {
  OPEN: string;
  EDIT: string;
  CLOSE_SUCCESSFUL: string;
  CLOSE_UNSUCCESSFUL: string;
  CANCELLED: string;
};

export const EDITABLE_VACANCIES_STATUSES = ['OPEN', 'EDIT', 'TEMPLATE'];

export const VACANCIES_STATUSES = ['OPEN', 'EDIT', 'CLOSE_SUCCESSFUL', 'CLOSE_UNSUCCESSFUL', 'CANCELLED'];

export const VACANCIES_STATUSES_VALUES = {
  OPEN: 'OPEN',
  EDIT: 'EDIT',
  CLOSE_SUCCESSFUL: 'CLOSE_SUCCESSFUL',
  CLOSE_UNSUCCESSFUL: 'CLOSE_UNSUCCESSFUL',
  CANCELLED: 'CANCELLED',
};

export const VACANCIES_STATUSES_LABELS = () => ({
  OPEN: i18next.t('vacancyOpen'),
  EDIT: i18next.t('vacancyEdit'),
  CLOSE_SUCCESSFUL: i18next.t('vacancyCloseSuccessful'),
  CLOSE_UNSUCCESSFUL: i18next.t('vacancyCloseUnSuccessful'),
  CANCELLED: i18next.t('vacancyCancelled'),
});

export const VACANCIES_CREATE = ['EDIT', 'TEMPLATE'];

export const VACANCIES_FROM_TEMPLATE = [VACANCIES_STATUSES_VALUES.EDIT];

export const VACANCIES_FROM_TEMPLATE_LABELS = () => ({
  [VACANCIES_STATUSES_VALUES.EDIT]: i18next.t('vacancyEdit'),
});

export const VACANCIES_CREATE_VALUES = {
  EDIT: 'EDIT',
  TEMPLATE: 'TEMPLATE',
};

export const VACANCIES_CREATE_LABELS = () => ({
  EDIT: i18next.t('vacancyEdit'),
  TEMPLATE: i18next.t('vacancyTemplate'),
});

export const VACANCIES_ARCHIVE_TEMPLATE = ['ARCHIVE', 'TEMPLATE'];

export const VACANCIES_ARCHIVE_TEMPLATE_VALUES = {
  ARCHIVE: 'ARCHIVE',
  TEMPLATE: 'TEMPLATE',
};

export const VACANCIES_OPEN_TEMPLATE = ['OPEN', 'CANCELLED', 'CLOSE_SUCCESSFUL', 'CLOSE_UNSUCCESSFUL'];

export const VACANCIES_OPEN_LABELS = () => ({
  OPEN: i18next.t('vacancyOpen'),
  CANCELLED: i18next.t('vacancyCancelled'),
  CLOSE_SUCCESSFUL: i18next.t('vacancyCloseSuccessful'),
  CLOSE_UNSUCCESSFUL: i18next.t('vacancyCloseUnSuccessful'),
});

export const chipsColors = [
  {
    status: VacancyStatusEnum.OPEN,
    backgroundColor: '#DDE7FA',
    color: theme.palette.primary.main,
  },
  {
    status: VacancyStatusEnum.EDIT,
    backgroundColor: '#FFF9F4',
    color: '#FC8035',
  },
  {
    status: VacancyStatusEnum.CLOSE_SUCCESSFUL,
    backgroundColor: '#E6F8E6',
    color: '#1B9420',
  },
  {
    status: VacancyStatusEnum.CLOSE_UNSUCCESSFUL,
    backgroundColor: '#FFF2F0',
    color: theme.palette.error.main,
  },
  {
    status: VacancyStatusEnum.CANCELLED,
    backgroundColor: '#F3F5F7',
    color: '#C2C2C2',
  },
];
