import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { GET_COMMENTS_FOR_CANDIDATE_SUCCESS, CLEAR_CANDIDATE_COMMENTS } from '../actions/candidateComments';
import { ICandidateComment } from 'types/api/candidates';

type InitialState = {
  commentsOfSelectedCandidate: ICandidateComment[];
};

const initialState: InitialState = {
  commentsOfSelectedCandidate: [],
};

const handlers = {
  [GET_COMMENTS_FOR_CANDIDATE_SUCCESS.type]: (
    prevState: InitialState,
    { payload }: PayloadAction<ICandidateComment[]>,
  ) => {
    return {
      ...prevState,
      commentsOfSelectedCandidate: payload,
    };
  },

  [CLEAR_CANDIDATE_COMMENTS.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      commentsOfSelectedCandidate: [],
    };
  },
};

export default createReducer(initialState, handlers);
