import { createTheme, Shadows } from '@mui/material/styles';
import { additionalComponentsConfig, componentsConfig } from './components.config';

import { additionalPaletteConfig, paletteConfig } from './palette.config';
import { additionalTypographyConfig, typographyConfig } from './typography.config';

declare module '@mui/material/styles' {
  interface Palette {
    shadow: Palette['primary'];
  }

  interface PaletteOptions {
    shadow?: PaletteOptions['primary'];
    darkgrey?: Palette['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true;
  }
}

let theme = createTheme({
  palette: paletteConfig,
  typography: typographyConfig,
  components: componentsConfig,
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 750,
    },
  },
  shadows: Array(25).fill('none') as Shadows,
});

theme = createTheme(theme, {
  palette: additionalPaletteConfig(theme),
  typography: additionalTypographyConfig(theme),
  components: additionalComponentsConfig(theme),
  shadows: theme.shadows.map((shadow, index) =>
    index === 3
      ? '0px 5px 15px 0px rgba(0, 0, 0, 0.08), 0px 15px 35px -5px rgba(17, 24, 38, 0.15), 0px 0px 0px 1px rgba(152, 161, 178, 0.10)'
      : shadow,
  ),
});

export default theme;
