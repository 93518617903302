import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    avatars: {
      display: 'flex',
      marginLeft: '-16px',
    },
    avatar: {
      width: '36px',
      height: '36px',
      fontSize: '1rem',
      border: `1px solid ${theme.palette.primary.contrastText}`,
    },
    teamAvatar: {
      left: '18px',
    },
  };
});

export default useStyles;
