import { store } from '../../../index';
import { selectedTeamStorage } from '../../../../helpers/storageFunctions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ITeams } from '../../../../types/api/teams';
import { getTeamById } from 'redux/actions/teams';

export function* onChangeEmployeeRole(action: PayloadAction<ITeams>) {
  const { dispatch } = store;

  dispatch(getTeamById({}));
  selectedTeamStorage().setSelectedTeam(action.payload);

  yield true;
}
