import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import {
  GET_ALL_CHANNELS_SUCCESS,
  CREATE_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_SUCCESS,
} from '../actions/channels';
import { IChannels } from '../../types/api/channels';

type InitialState = {
  channels: IChannels[];
};

const initialState: InitialState = {
  channels: [],
};

const handlers = {
  [GET_ALL_CHANNELS_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IChannels[]>) => {
    return {
      ...prevState,
      channels: payload,
    };
  },

  [UPDATE_CHANNEL_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IChannels>) => {
    const copiedChannel = [...prevState.channels];
    const replacedChannels = copiedChannel.map((spec) => {
      if (spec?._id === payload?._id) {
        spec = payload;
      }
      return spec;
    });

    return {
      ...prevState,
      channels: [...replacedChannels],
    };
  },

  [CREATE_CHANNEL_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IChannels>) => {
    const prevChannel = [...prevState?.channels];
    const newCandidateChannel = [payload, ...prevChannel];
    return {
      ...prevState,
      channels: newCandidateChannel,
    };
  },

  [DELETE_CHANNEL_SUCCESS.type]: (prevState: InitialState) => {
    return {
      ...prevState,
    };
  },
};

export default createReducer(initialState, handlers);
