import { RoutesConstants } from 'constants/routesConstants';
import { languageStore } from 'helpers/storageFunctions';

function* onLogOut() {
  const lang = languageStore().getLanguage() ?? 'en';
  const lastVisitedPage = localStorage.getItem('lastVisited') ?? RoutesConstants.HOMEPAGE;

  localStorage.clear();
  localStorage.setItem('lastVisited', lastVisitedPage);
  localStorage.setItem('language', lang);

  yield true;
}

export default onLogOut;
