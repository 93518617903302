import { store } from '../../../index';
import { changeUserParameter } from '../../../actions/users';

function* onChangeParameter(action) {
  const { dispatch } = store;
  const numberPattern = /\d+/g;

  if (action.method === 'DELETE') {
    dispatch(
      changeUserParameter({
        id: Number(action?.endpoint.match(numberPattern)),
        parametr: 'status',
        value: 'DEACTIVATED',
      }),
    );
  }

  if (action.query) {
    for (const key in action.query) {
      dispatch(
        changeUserParameter({
          id: Number(action?.endpoint.match(numberPattern)),
          parametr: key,
          value: action.query[key],
        }),
      );
    }
  }
  yield true;
}

export default onChangeParameter;
