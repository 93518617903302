import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_REMINDER_SUCCESS,
  DELETE_REMINDER_SUCCESS,
  MARK_REMINDERS_AS_READ_SUCCESS,
  UPDATE_REMINDER_SUCCESS,
} from '../../../actions/candidateReminders';
import { onGetReminders } from './onGetReminders';

export default function* fn() {
  yield takeLatest(UPDATE_REMINDER_SUCCESS.type, onGetReminders);
  yield takeLatest(DELETE_REMINDER_SUCCESS.type, onGetReminders);
  yield takeLatest(MARK_REMINDERS_AS_READ_SUCCESS.type, onGetReminders);
}
