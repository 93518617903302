import { PayloadAction } from '@reduxjs/toolkit';
import { getReminders, getTodayRemindersStatus } from '../../../actions/candidateReminders';
import { store } from '../../../index';

export function* onGetReminders(
  action: PayloadAction<{
    authorId: string;
    candidateId: string;
    createdAt: string;
    date: string;
    description: string;
    teamId: string;
    updatedAt: string;
    __v: number;
    _id: string;
  }>,
) {
  const { dispatch } = store;
  dispatch(
    getReminders({
      filters: {
        page: '',
        offset: '',
        candidateId: action?.payload?.candidateId,
        date: '',
      },
      onFailure: () => {},
    }),
  );
  dispatch(getTodayRemindersStatus());
  yield true;
}
