import { Theme } from '@mui/material';

export const typographyConfig = {
  fontFamily: "'Montserrat', sans-serif",
  lineHeight: '150%',
  fontWeight: 400,
  fontSize: Number('1rem'),
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.75rem',
  },
  h1: {
    fontWeight: 700,
    fontSize: '2rem',
  },
  h2: {
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  h4: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  button: {
    fontSize: '1rem',
    fontWeight: 600,
  },
};

export const additionalTypographyConfig = (theme: Theme) => {
  return {
    color: theme.palette.text.primary,
  };
};
