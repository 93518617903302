import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    invitePeopleInputHelperText: {
      color: theme.palette.primary.main,
      textAlign: 'right',
      fontSize: '0.875rem',
    },
    invitePeopleFormControl: {
      marginBottom: 0,
    },
    addedEmail: {
      color: '#71D86A',
      fontSize: '1.5rem',
      marginRight: '8px',
    },
    invitedMail: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '14px',
      '&:last-child': {
        marginBottom: '24px',
      },
    },
    desktopInput: {
      display: 'block',

      [theme.breakpoints.down('mobile')]: {
        display: 'none',
      },
    },
    mobileInput: {
      display: 'none',

      [theme.breakpoints.down('mobile')]: {
        display: 'block',
      },
    },
    navLink: {
      marginTop: '4px',
      border: `8px solid transparent`,
      minWidth: '235px',
      transition: 'all 150ms linear',

      '&:hover': {
        backgroundColor: 'rgba(69, 43, 228, 0.15)',
        transition: 'all 150ms linear',
      },
    },
    modalTitle: {
      fontSize: '1.25rem',
      fontWeight: 700,
      marginBottom: '24px',
      textAlign: 'center',
    },
    divider: {
      margin: '16px 0',
      borderColor: '#DDE7FA',
    },
    dividerMobile: {
      display: 'none',

      [theme.breakpoints.down('mobile')]: {
        display: 'block',
      },
    },
    dividerDesktop: {
      [theme.breakpoints.down('mobile')]: {
        display: 'none',
      },
    },
    userRoleTitle: {
      fontWeight: 500,
      marginBottom: '24px',
    },
    rolesContainer: {
      [theme.breakpoints.up('mobile')]: {
        margin: 0,
      },
    },
    roleButton: {
      marginBottom: 0,
    },
    formButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '12px',

      '& > :not(:first-of-type)': {
        marginLeft: '16px',
      },
    },
    button: {
      minWidth: '150px',
      textTransform: 'inherit',
      padding: '11px 16px',
      fontSize: '14px',
      lineHeight: '1.5rem',
    },
  };
});

export default useStyles;
