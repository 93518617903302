import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    formControl: {
      marginBottom: '0',
    },
    label: {
      '& .MuiFormLabel-asterisk': {
        color: theme.palette.error.main,
      },
    },
    variantedInput: {
      borderRadius: '4px',
      lineHeight: '1.135rem',
      backgroundColor: theme.palette.background.paper,
      borderColor: 'rgba(0, 0, 0, 0.23)',

      '& > .MuiOutlinedInput-notchedOutline': {
        transition: 'all 150ms linear',
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
        transition: 'all 150ms linear',
      },
    },
    input: {
      padding: '12px 14px',
      lineHeight: '1.5rem',
      height: 'auto',

      [theme.breakpoints.down('mobile')]: {
        lineHeight: '1.35rem',
      },
    },
    helperText: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 0 0 7px',
      fontSize: '0.75rem',
      minHeight: '24px',

      [theme.breakpoints.down('mobile')]: {
        fontSize: '0.6rem',
        marginLeft: '3px',
      },
    },
  };
});

export default useStyles;
