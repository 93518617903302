import { AxiosError } from 'axios';

export function getErrorMessage(error: unknown) {
  if (error instanceof AxiosError) {
    return error.response?.data.error;
  } else if (error instanceof Error) {
    return error.message;
  }
  return String(error);
}
