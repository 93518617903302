import { format } from 'date-fns';
import { ITeams } from 'types/api/teams';

export function tokenStorage() {
  const getAccessToken = () => localStorage.getItem('access-token');
  const getRefreshToken = () => localStorage.getItem('refresh-token');
  const setAccessToken = (token: string) => localStorage.setItem('access-token', token);
  const setRefreshToken = (token: string) => localStorage.setItem('refresh-token', token);

  return { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken };
}

export function activeProjectsStorage() {
  const getActive = () => JSON.parse(localStorage.getItem('showActiveProjects') ?? JSON.stringify('false'));
  const setActive = (isActive: boolean) => localStorage.setItem('showActiveProjects', JSON.stringify(isActive));

  return { getActive, setActive };
}

export function activeReportProjectsStorage() {
  const getActive = () => JSON.parse(localStorage.getItem('showActiveReportProjects') ?? JSON.stringify('false'));
  const setActive = (isActive: boolean) => localStorage.setItem('showActiveReportProjects', JSON.stringify(isActive));

  return { getActive, setActive };
}

export function activeUsersStorage() {
  const getActive = () => JSON.parse(localStorage.getItem('showActiveUsers') ?? JSON.stringify('true'));
  const setActive = (isActive: boolean) => localStorage.setItem('showActiveUsers', JSON.stringify(isActive));

  return { getActive, setActive };
}

export function selectedProjectIdStorage() {
  const getProjectId = () => JSON.parse(localStorage.getItem('selectedProjectId') ?? JSON.stringify(''));
  const setProjectId = (projectId: string) => localStorage.setItem('selectedProjectId', JSON.stringify(projectId));

  return { getProjectId, setProjectId };
}

export function languageStore() {
  const setLanguage = (lang: string) => localStorage.setItem('language', lang);
  const getLanguage = () => localStorage.getItem('language');

  return { setLanguage, getLanguage };
}

export function fridayTimelogStatus() {
  const setStatus = (status: { status: string; date: Date }) =>
    localStorage.setItem('fridayStatus', JSON.stringify({ status, date: format(new Date(), 'yyyy-MM-dd') }));
  const getStatus = () => JSON.parse(localStorage.getItem('fridayStatus') ?? JSON.stringify('{}'));

  return { setStatus, getStatus };
}

export function toggleHRandRecruitmentMenuStorage() {
  const getHRandRecruitmentMenu = () =>
    JSON.parse(localStorage.getItem('HRandRecruitmentMenu') ?? JSON.stringify('true'));
  const setHRandRecruitmentMenu = (toggleMenu: boolean) =>
    localStorage.setItem('HRandRecruitmentMenu', JSON.stringify(toggleMenu));

  return { getHRandRecruitmentMenu, setHRandRecruitmentMenu };
}

export function toggleReportsMenuStorage() {
  const getReportsMenu = () => JSON.parse(localStorage.getItem('reportsMenu') ?? JSON.stringify(''));
  const setReportsMenu = (toggleMenu: boolean) => localStorage.setItem('reportsMenu', JSON.stringify(toggleMenu));

  return { getReportsMenu, setReportsMenu };
}

export function manipulateLocalStorage() {
  const getValueFromLocalStorage = (value: any) =>
    JSON.parse(localStorage.getItem(`${value}`) ?? JSON.stringify('false'));
  const removeValueFromLocalStorage = (value: any) => localStorage.removeItem(`${value}`);

  return { getValueFromLocalStorage, removeValueFromLocalStorage };
}

export function projectFiltersStorage() {
  const setProjectFilter = (filters: any) => localStorage.setItem('savedFilters', JSON.stringify(filters));
  const getProjectFilter = () => JSON.parse(localStorage.getItem('savedFilters') ?? JSON.stringify('{}'));

  return { setProjectFilter, getProjectFilter };
}

export function selectedTeamStorage() {
  const getSelectedTeam = () => JSON.parse(localStorage.getItem('team') ?? JSON.stringify('null'));
  const setSelectedTeam = (team: ITeams | null) => localStorage.setItem('team', JSON.stringify(team));
  return { getSelectedTeam, setSelectedTeam };
}

export function prevPageUrlStorage() {
  const getPrevPageUrl = (): string[] => JSON.parse(localStorage.getItem('prevPageUrl') ?? JSON.stringify(['/']));
  const setPrevPageUrl = (pageUrl: string[]) => localStorage.setItem('prevPageUrl', JSON.stringify(pageUrl));

  return { getPrevPageUrl, setPrevPageUrl };
}

export function sidebarStorage() {
  const getSidebarState = () => JSON.parse(localStorage.getItem('sidebarOpen') ?? 'true');
  const setSidebarState = (isOpen: boolean) => localStorage.setItem('sidebarOpen', JSON.stringify(isOpen));

  return { getSidebarState, setSidebarState };
}
