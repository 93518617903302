import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { TableFilter } from 'redux/reducers/teams';

export const CHANGE_CANDIDATES_LIST_FILTERS = createAction<TableFilter[], string>(
  'candidates/CHANGE_CANDIDATES_LIST_FILTERS',
);

export const changeCandidatesListFilters = (candidatesFilter: TableFilter[]) => (dispatch: AppDispatch) => {
  dispatch(CHANGE_CANDIDATES_LIST_FILTERS(candidatesFilter));
};
