import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { prevPageUrlStorage } from '../../../../helpers/storageFunctions';
import NavBarButton from '../../../../components/navigations-section/navbar-button';
import useStyles from './style';
import { useMediaQuery } from '@mui/material';
import theme from 'theme/theme';

type SidebarLinkProps = {
  route: string;
  vacancyRoute?: string;
  label: string;
  icon: JSX.Element | null;
  toggleDrawer: Dispatch<SetStateAction<boolean>>;
  changeSidebarVisibility: (isMobile: boolean) => void;
  isSidebarOpen: boolean;
};

const SidebarLink: React.FC<SidebarLinkProps> = ({
  route,
  vacancyRoute,
  label,
  icon,
  toggleDrawer,
  changeSidebarVisibility,
  isSidebarOpen,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const { classes } = useStyles({ isSidebarOpen });

  const closeDrawer = () => {
    prevPageUrlStorage().setPrevPageUrl([]);
    changeSidebarVisibility(isMobile);
    toggleDrawer(false);
    history.push(`/${route}`);
  };

  return (
    <div onClick={closeDrawer} className={classes.navbarLink}>
      <NavBarButton
        text={t(label)}
        href={route}
        vacancyRoute={vacancyRoute ?? ''}
        icon={icon}
        isSidebarOpen={isSidebarOpen}
      />
    </div>
  );
};

export default SidebarLink;
