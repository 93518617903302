import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_REVIEWS_FOR_USER_SUCCESS = createAction('userReviews/GET_REVIEWS_FOR_USER.SUCCESS');
export const getReviewsForUser = (userId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/reviews/user/${userId}`,
    });
    dispatch(GET_REVIEWS_FOR_USER_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_USER_REVIEW_SUCCESS = createAction('userReviews/CREATE_USER_REVIEW.SUCCESS');
export const CREATE_USER_REVIEW_FAILURE = createAction('userReviews/CREATE_USER_REVIEW.FAILURE');
export const createUserReview = (userId, reviewData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `users/reviews/`,
      query: { ...reviewData, userId },
    });
    dispatch(CREATE_USER_REVIEW_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_USER_REVIEW_FAILURE(err));
    onFailure(err.response.data.error);
  }
};

export const UPDATE_USER_REVIEW_SUCCESS = createAction('userReviews/UPDATE_USER_REVIEW.SUCCESS');
export const UPDATE_USER_REVIEW_FAILURE = createAction('userReviews/UPDATE_USER_REVIEW.FAILURE');
export const updateUserReview = (reviewId, reviewData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/reviews/${reviewId}`,
      query: reviewData,
    });
    dispatch(UPDATE_USER_REVIEW_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(UPDATE_USER_REVIEW_FAILURE(err));
    onFailure(err.response.data.error);
  }
};

export const DELETE_USER_REVIEW_SUCCESS = createAction('userReviews/DELETE_USER_REVIEW.SUCCESS');
export const DELETE_USER_REVIEW_FAILURE = createAction('userReviews/DELETE_USER_REVIEW.FAILURE');
export const deleteUserReview = (reviewId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `users/reviews/${reviewId}`,
    });
    dispatch(DELETE_USER_REVIEW_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_USER_REVIEW_FAILURE(err));
    onFailure(err.response.data.error);
  }
};
