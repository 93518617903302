import { Avatar } from '@mui/material';
import useStyles from './style';
import { getCurrentUser } from '../../redux/selectors/auth';
import { useSelector } from 'react-redux';
import { selectedTeamStorage } from '../../helpers/storageFunctions';

type ProfilesIconsProps = {
  onClick?: () => void;
  changeSidebarVisibility?: (openOnClick?: boolean) => void;
};

const ProfilesIcons: React.FC<ProfilesIconsProps> = ({ onClick, changeSidebarVisibility }) => {
  const { classes } = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const amazonUrl = `${process.env.REACT_APP_AMAZON_URL}/avatars/`;
  const userNameFirstLetter = currentUser?.firstNameLatin?.split('', 1).join('');
  const userLastNameFirstLetter = currentUser?.lastNameLatin?.split('', 1).join('');
  const teamName = selectedTeamStorage().getSelectedTeam()?.name;
  const shortTeamName = teamName?.split('', 1).join('');

  return (
    <div
      className={classes.avatars}
      onClick={() => {
        changeSidebarVisibility?.(false);
        onClick?.();
      }}
      style={!teamName ? { marginLeft: 0 } : {}}
    >
      {shortTeamName && (
        <Avatar alt='avatar' className={`${classes.avatar} ${classes.teamAvatar}`}>
          {shortTeamName}
        </Avatar>
      )}
      <Avatar alt='avatar' src={amazonUrl + currentUser?.currentAvatar} className={classes.avatar}>
        {userNameFirstLetter + userLastNameFirstLetter}
      </Avatar>
    </div>
  );
};

export default ProfilesIcons;
