import { useLocation } from 'react-router-dom';
import useStyles from './style';

type NavBarButtonProps = {
  text: string;
  href: string;
  vacancyRoute: string;
  icon: JSX.Element | null;
  isSidebarOpen: boolean;
};

const NavBarButton: React.FC<NavBarButtonProps> = ({ text, href, icon, isSidebarOpen }) => {
  const location = useLocation<any>();
  const current = location.pathname.split('/')[1] === href.split('/')[0];

  const { classes } = useStyles({ isSidebarOpen });
  const className = current ? `${classes.currentPage} ${classes.navButton}` : classes.navButton;

  const icons = () => {
    return icon;
  };

  return (
    <div className={className}>
      <div className={classes.iconContainer}>{icons()}</div>
      <div className={classes.navButtonTextContainer}>
        <span title={text}>{text}</span>
      </div>
    </div>
  );
};

export default NavBarButton;
