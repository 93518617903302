import { PayloadAction } from '@reduxjs/toolkit';
import { getClientById } from '../../../actions/clients';
import { store } from '../../../index';
import { IClient } from 'redux/reducers/clients';

export function* onDeleteClientAvatar({ payload }: PayloadAction<IClient>) {
  const { dispatch } = store;

  dispatch(getClientById({ clientId: payload._id }));

  yield true;
}
