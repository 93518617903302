import { createReducer } from '@reduxjs/toolkit';
import { GET_REVIEWS_FOR_USER_SUCCESS } from '../actions/userReviews';

const initialState = {
  reviewsOfSelectedUser: [],
};

const handlers = {
  [GET_REVIEWS_FOR_USER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      reviewsOfSelectedUser: payload,
    };
  },
};

export default createReducer(initialState, handlers);
