import React, { useState, useEffect, useRef } from 'react';

import { TextField, Typography } from '@mui/material';

import { InputProps } from '../../types/components/inputTypes';
import { useWindowWidth } from 'hooks/useWindowWidth';

import useStyles from './styles';
import { styleConstants } from 'constants/styleConstants';

const CustomInput: React.FC<InputProps> = ({
  defaultValue = '',
  onChange,
  inputType = 'text',
  label = '',
  name = '',
  variant = 'outlined',
  margin = 'none',
  size = 'medium',
  isRequired = false,
  onBlur,
  onFocus,
  onKeyDownPress,
  showErrorText = false,
  formikError = '',
  helperText = '',
  disabled = false,
  formControlStyle = '',
  inputLabelStyle = '',
  inputStyle = '',
  variantInputStyle = '',
  helperTextStyle = '',
  endAdornment = false,
  startAdornment = false,
  isReset = false,
  setIsReset,
  multiline = false,
  minRows = 2,
  min = 0,
  max,
  step = 1,
  searchValue,
}) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [isInFocus, setIsInFocus] = useState(false);
  const dimensions = useWindowWidth();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (searchValue !== undefined) {
      setInputValue(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    inputRef.current!.parentElement?.classList.remove('Mui-disabled');
    inputRef.current!.removeAttribute('disabled');
    inputRef.current!.classList.remove('Mui-disabled');
  }, [showErrorText]);

  useEffect(() => {
    if (isReset && setIsReset) {
      setInputValue('');
      onChange('');
      setIsReset(false);
    }
  }, [isReset]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setInputValue(value);
    onChange(value);
  };

  const enterPressHandle = (event: React.KeyboardEvent<Element>) => {
    if (onKeyDownPress && event.key === 'Enter' && !showErrorText && !formikError) {
      onKeyDownPress(event);
      setInputValue('');
    }
  };

  const getHelperText = (helperText: string | false, showErrorText: boolean) => {
    if (helperText) {
      if (showErrorText) {
        return (
          <>
            <Typography className={`${classes.helperText} ${helperTextStyle}`} component='span' color='error'>
              {formikError}
            </Typography>
            {formikError !== helperText && (
              <Typography
                className={`${classes.helperText} ${helperTextStyle}`}
                component='span'
                style={{ marginBottom: helperText !== ' ' ? '6px' : '0' }}
                color='primary'
              >
                {helperText}
              </Typography>
            )}
          </>
        );
      } else {
        return (
          <>
            <Typography
              className={`${classes.helperText} ${helperTextStyle}`}
              component='span'
              color='primary'
              style={{ marginLeft: 'auto', marginBottom: helperText !== ' ' ? '6px' : '0' }}
            >
              {helperText}
            </Typography>
          </>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <TextField
      className={`${classes.formControl} ${formControlStyle}`}
      variant={variant}
      size={dimensions < 750 ? 'medium' : size}
      fullWidth
      margin={margin}
      error={showErrorText}
      InputLabelProps={{
        htmlFor: `${name}-outlined-input`,
        className: `${classes.label} ${inputLabelStyle}`,
        style: !isInFocus && !inputValue ? { top: styleConstants.inputLabelOffset } : {},
      }}
      label={label}
      required={isRequired}
      multiline={multiline}
      minRows={minRows}
      InputProps={{
        id: `${name}-outlined-input`,
        ref: inputRef,
        label: label,
        type: inputType,
        value: inputValue,
        onChange: handleChange,
        onBlur: () => setIsInFocus(false),
        onFocus: () => setIsInFocus(true),
        onKeyDown: enterPressHandle,
        endAdornment: endAdornment,
        startAdornment: startAdornment,
        className: `${classes.variantedInput} ${variantInputStyle}`,
        disabled: disabled,
        'aria-describedby': `${name}-helper-text`,
        autoComplete: 'new-password',
      }}
      inputProps={{
        min: min,
        max: max,
        step: step,
        variant: variant,
        className: `${inputStyle} ${classes.input}`,
        sx: dimensions < 750 ? { padding: '12px 14px' } : {},
      }}
      helperText={getHelperText(helperText, showErrorText)}
      FormHelperTextProps={{
        id: `${name}-helper-text`,
        className: `${classes.helperText} ${helperTextStyle}`,
      }}
    />
  );
};

export default CustomInput;
