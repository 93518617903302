import { roles, employeeRolesEnum } from '../assets/roles';

const { ADMIN } = roles;
const { COMPANY_OWNER, COMPANY_ADMIN } = employeeRolesEnum;

class RolePermissionsHelper {
  canEditMember(memberRole: employeeRolesEnum) {
    switch (memberRole) {
      case COMPANY_OWNER:
      case COMPANY_ADMIN:
        return true;
      default:
        return false;
    }
  }

  canResetPassword(userRole: string) {
    switch (userRole) {
      case ADMIN:
        return true;
      default:
        return false;
    }
  }

  canInviteMembers(memberRole: employeeRolesEnum) {
    const rolesWithPermission = [COMPANY_OWNER, COMPANY_ADMIN].includes(memberRole);
    if (!rolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canEditTeam(userTeamRole: string) {
    return userTeamRole === COMPANY_OWNER;
  }

  canEditEmployee(userTeamRole: string) {
    return userTeamRole === COMPANY_OWNER || userTeamRole === COMPANY_ADMIN;
  }

  canAccessSections(userRole: string, teamRole: string) {
    if (userRole === ADMIN || teamRole) {
      return ['candidate', 'candidates', 'vacancies', 'clients', 'reminders'];
    } else {
      return [];
    }
  }
}

export const RolePermissions = new RolePermissionsHelper();
