import { Theme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    cardBackground: string;
    scrollbar: string;
    backgroundBord: string;
    black: string;
    bottomBorder: string;
  }
  interface PaletteOptions {
    cardBackground: string;
    scrollbar: string;
    backgroundBord: string;
    black: string;
    bottomBorder: string;
  }
}

export const paletteConfig = {
  primary: {
    light: '#9A8CEF',
    main: '#452BE4',
    dark: '#2608DC',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#FDFDFD',
    light: '#E8E8E8',
    dark: '#DDE7FA',
    contrastText: '#6F6F70',
  },
  error: {
    main: '#FD4F4F',
    light: '#F5B7B7',
    dark: '#FE3030',
    contrastText: '#FFF',
  },
  success: {
    main: '#48AE4C',
    light: '#B1DAB3',
    dark: '#348A37',
    contrastText: '#F2F4FF',
  },
  text: {
    primary: '#0F0F10',
  },
  background: {
    default: '#FAFCFA',
    paper: '#FFF',
  },
  divider: '#E1E1E1',
  grey: {
    200: 'rgba(225, 225, 225, 0.20)',
  },
  cardBackground: '#F9FCFF',
  scrollbar: 'rgba(69, 43, 228, 0.2)',
  backgroundBord: '#F8F9FF',
  black: '#000',
  bottomBorder: '#F3F5F7',
};

export const additionalPaletteConfig = (theme: Theme) => {
  return {
    shadow: theme.palette.augmentColor({
      color: {
        main: '#E5E5E5',
      },
      name: 'shadow',
    }),
  };
};
