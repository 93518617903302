import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    dialogContainer: {
      padding: '16px 24px',

      [theme.breakpoints.down('mobile')]: {
        padding: '24px 16px',
      },
    },
    dialogTitle: {
      fontSize: '1.25rem',
      fontWeight: 700,
      textAlign: 'center',
      padding: 0,
      marginBottom: '16px',
    },
    dialogSubtitle: {
      fontWeight: 500,
      color: '#6F6F70',
      textAlign: 'center',
    },
    divider: {
      margin: '16px 0',
      borderColor: '#DDE7FA',
    },
    dialogActions: {
      justifyContent: 'center',
      padding: 0,

      '& > :not(:first-of-type)': {
        marginLeft: '16px',
      },

      [theme.breakpoints.down('mobile')]: {
        justifyContent: 'space-between',
      },
    },
    button: {
      textTransform: 'inherit',
      minWidth: '150px',

      [theme.breakpoints.down('mobile')]: {
        padding: '11px 14px',
      },
    },
  };
});

export default useStyles;
