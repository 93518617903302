import { Theme } from '@mui/material';
import { styleConstants } from 'constants/styleConstants';

export const componentsConfig = {
  MuiButton: {
    styleOverrides: {
      root: {
        padding: '11px',
        borderRadius: styleConstants.buttonBorderRadius,
        boxShadow: 'none',
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: {
        fontSize: '1rem',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        fontSize: '1.5rem',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
      },
    },
  },
  MuiDayCalendar: {
    styleOverrides: {
      weekDayLabel: {
        fontSize: '1rem',
      },
    },
  },
};
export const additionalComponentsConfig = (theme: Theme) => {
  return {
    MuiIconButton: {
      variants: [
        {
          props: { color: 'error' },
          style: {
            backgroundColor: theme.palette.error.main,
            border: '1px solid transparent',
            color: theme.palette.error.contrastText,
            transition: 'all 150ms linear',

            '&:hover': {
              backgroundColor: theme.palette.error.main,
              border: `1px solid ${theme.palette.error.dark}`,
              boxShadow: '2px 4px 16px 0px rgba(253, 79, 79, 0.25)',
              transition: 'all 150ms linear',
            },

            '&:active': {
              backgroundColor: theme.palette.error.dark,
            },

            '&:disabled': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.contrastText,
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: '1px solid transparent',
          lineHeight: 1.5,
          transition: 'all 150ms linear',

          '&:disabled': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: 'none',
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            border: '1px solid #2608DC',
            boxShadow: '2px 4px 16px 0px rgba(69, 43, 228, 0.25)',
            transition: 'all 150ms linear',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: theme.palette['secondary'].main,
            border: '1px solid #DADADA',
            color: theme.palette.secondary.contrastText,
            transition: 'all 150ms linear',

            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              border: '1px solid #DADADA',
              boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.25)',
              transition: 'all 150ms linear',
            },

            '&:active': {
              backgroundColor: theme.palette.secondary.main,
              border: `1px solid ${theme.palette.secondary.contrastText}`,
            },

            '&:disabled': {
              backgroundColor: theme.palette.secondary.light,
              color: 'rgba(111, 111, 112, 0.5)',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            backgroundColor: theme.palette.error.main,
            border: '1px solid transparent',
            color: theme.palette.error.contrastText,
            transition: 'all 150ms linear',

            '&:hover': {
              backgroundColor: theme.palette.error.main,
              border: `1px solid ${theme.palette.error.dark}`,
              boxShadow: '2px 4px 16px 0px rgba(253, 79, 79, 0.25)',
              transition: 'all 150ms linear',
            },

            '&:active': {
              backgroundColor: theme.palette.error.dark,
            },

            '&:disabled': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.contrastText,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.error.light}`,
            color: theme.palette.error.main,
            transition: 'all 150ms linear',

            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              border: `1px solid ${theme.palette.error.light}`,
              boxShadow: '2px 4px 16px 0px rgba(253, 79, 79, 0.25)',
              transition: 'all 150ms linear',
            },

            '&:active': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.error.main,
              border: `1px solid ${theme.palette.error.main}`,
            },

            '&:disabled': {
              backgroundColor: '#E8E8E8',
              color: theme.palette.error.light,
              border: `1px solid ${theme.palette.error.light}`,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            backgroundColor: theme.palette.success.main,
            border: '1px solid transparent',
            color: theme.palette.primary.contrastText,
            transition: 'all 150ms linear',

            '&:hover': {
              backgroundColor: theme.palette.success.main,
              border: `1px solid ${theme.palette.success.dark}`,
              boxShadow: '2px 4px 16px 0px rgba(72, 174, 76, 0.25)',
              transition: 'all 150ms linear',
            },

            '&:active': {
              backgroundColor: theme.palette.success.dark,
            },

            '&:disabled': {
              backgroundColor: theme.palette.success.light,
              color: theme.palette.primary.contrastText,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            transition: 'all 150ms linear',

            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
              border: `1px solid ${theme.palette.primary.main}`,
              boxShadow: 'box-shadow: 2px 4px 16px 0px rgba(69, 43, 228, 0.25)',
              transition: 'all 150ms linear',
            },

            '&:active': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.primary.dark}`,
            },

            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.light,
              border: `1px solid ${theme.palette.primary.light}`,
            },
          },
        },
      ],
    },
  };
};
