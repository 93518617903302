import { PayloadAction } from '@reduxjs/toolkit';
import { getAllChannels } from '../../../actions/channels';
import { store } from '../../../index';
import { IChannels } from '../../../../types/api/channels';

export function* onGetChannels({ payload }: PayloadAction<IChannels>) {
  const { dispatch } = store;

  dispatch(getAllChannels({ refersTo: payload?.refersTo }));

  yield true;
}
