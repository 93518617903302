export const en = {
  translation: {
    reportSave: 'Save report',
    toastPasswordText: 'The Password was changed!',
    startDateVacancies: 'Start Date',
    endDateVacancies: 'End Date',
    typographyActionTextSignup: 'Not a member?',
    typographyActionTextLogin: 'Have an account?',
    typographyActionLinkSignup: 'Sign up',
    typographyActionLinkLogin: 'Sign in',
    typographyActionTextResetPassword: 'Forgot your password?',
    resetPasswordSubtitle: 'No problem! Lets create a new one',
    sendResetPasswordLink: 'Send me the link',
    sendResetPasswordNewLink: 'Send me new link',
    createNewPasswordTitle: 'Create new password',
    createNewPasswordSubtitle: 'You are creating new password for:',
    saveNewPasswordBtnText: 'Save new password',
    typographyActionLinkResetPassword: 'Reset password',
    typographyActionPasswordDidntMatch: "The two password fields didn't match",
    expiredLinkTitle: 'Whoops, that’s an expired link',
    expiredLinkText:
      'For security reasons, password reset link expire after a little while. If you still need to reset your password, you can request a new reset email.',
    expiredInviteTitle: 'Sorry, the invitation link has expired.',
    expiredInviteDescription: 'Please contact the person who shared the invitation link with you.',
    profileLabel: 'Profile',
    profileMyLabel: 'My profile',
    logoutLabel: 'Log out',
    alreadyHaveAccountQuestion: 'Already have an account?',
    dontHaveAccountQuestion: 'Don’t have an account yet?',
    invitedText: 'You were invited to join this team using:',
    welcomeText: 'Welcome to',
    teamText: 'Team',

    userTableFullName: 'Full Name',

    candidateHistoryAuthor: 'Author',
    candidateHistoryOldStatus: 'Old Status',
    candidateHistoryNewStatus: 'New Status',
    candidateHistoryDate: 'Date',

    commentsTableTitle: 'Title',
    commentsTableComment: 'Comment',
    commentsTableDate: 'Date',

    nonTrimmedField: 'Field should not contain spaces',
    nonValidEmail: 'Please enter a valid email',
    passwordsDontMatch: "The two password fields didn't match",
    requiredField: 'This field is required',
    requiredButton: 'One of the buttons must be selected',
    greaterThanField: 'Number should be greater than 0',
    integerField: 'Number should be integer',
    passwordHint:
      'The password must include: an uppercase letter, a lowercase letter, a number and a special character. The minimum number of characters is 8',
    securityTab: 'Security',
    historyTab: 'History',

    createUserTitle: 'Create an user',
    createCandidateTitle: 'Create a candidate',
    createCandidateSuccessMsg: 'Candidate has been successfully created',
    editCandidateSuccessMsg: 'Candidate has been successfully updated',
    createCandidateErrorMsg: 'Something went wrong',
    editUserTitle: 'Edit an user',
    editCandidateTitle: 'Edit a candidate',
    basicInfoTitle: 'Basic info',
    locationTitle: 'Location',
    contactInfoTitle: 'Contact information',
    additionalInformation: 'Additional information',
    financialInfoTitle: 'Financial information',
    hrInfoTitle: 'HR information',
    signUpTitle: 'Sign Up',
    signInTitle: 'Sign In',
    resetPasswordTitle: 'Reset password',
    resetPasswordOneTimeLinkTitle: 'The link to change the password has been sent to your email',
    resetPasswordSuccess: 'Password has been changed successfully',

    agreementsText: 'By clicking `Sign Up` you automatically agree to our',
    privacyPolicy: 'Privacy policy',

    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    firstNameLatinField: 'First Name (Latin)',
    lastNameLatinField: 'Last Name (Latin)',
    firstNameLocalField: 'First Name (Local)',
    lastNameLocalField: 'Last Name (Local)',
    birthdayField: 'Birthday',
    emailField: 'Email',
    emailLabel: 'Email',
    omiEmailField: 'OmiSoft email',
    mobilePhoneField: 'Phone',
    telegramField: 'Telegram',
    passwordField: 'Password',
    oldPasswordField: 'Old password',
    newPasswordField: 'New password',
    confirmPasswordField: 'Confirm password again',
    passwordLabel: 'Create password',
    repeatPasswordLabel: 'Repeat password',
    enterPasswordLabel: 'Enter password',
    reasonForDismissal: 'Reason for dismissal',
    specializationsField: 'Specializations',
    tagsField: 'Candidate tags',
    developerGradeField: 'Grade',
    techStackField: 'Tech stack',
    englishLevelField: 'English level',
    englishField: 'English',
    roleField: 'Role',
    candidateStatusField: 'Candidates status',
    openVacanciesField: 'Open vacancies',
    chosenVacancy: 'Vacancy',
    workingHoursField: 'Working hours',
    cvLinkField: 'CV link',
    otherLinksField: 'Other link',
    countryField: 'Country',
    cityField: 'City',
    novaPoshtaAddressField: 'Nova poshta adress',
    skypeField: 'Skype',
    upworkLinkField: 'UpWork link',
    gitHubField: 'GitHub username',
    gitLabField: 'GitLab username',
    linkedInField: 'LinkedIn',
    fopField: 'Are you FOP? (Private entrepreneur)',
    payoneerEmailField: 'Payoneer email',
    monobankCardField: 'Monobank card',
    privatBankCardField: 'Privat bank card',
    ipnField: 'Individual tax number',
    dateOfStartingField: 'Date of starting',
    dateOfBirthday: 'Date of Birthday',
    channelField: 'Channels',
    minSalaryField: 'Salary (Min)',
    maxSalaryField: 'Salary (Max)',
    minRateField: 'Hour rate (Min)',
    maxRateField: 'Hour rate (Max)',
    employmentTypeField: 'Employment type',

    saveButton: 'Save',
    addButton: 'Add',
    addNewButton: 'Add new',
    editButton: 'Edit',
    editProfileButton: 'Edit profile',
    avatarSelectMenu: 'You can select avatar',
    avatarSelectButton: 'Upload avatar',
    deleteButton: 'Delete',
    cancelButton: 'Close',
    applyButton: 'Apply',
    filterButton: 'Filters',
    hireButton: 'Hire',
    rejectButton: 'Reject',
    updateButton: 'Update',
    okButton: 'Ok',
    generatePasswordButton: 'Generate new password',
    createUserButton: 'Create user',
    createCandidateButton: 'Create candidate',
    editCandidateButton: 'Save changes',
    updateUserButton: 'Update user',
    updateCandidateButton: 'Update',
    savePasswordButton: 'Save password',
    submitBtn: 'Submit',
    submitFridayStatusTitle: 'Have you tracked your week?',
    submitFridayStatus: "Yes, I've tracked my week",
    denyFridayStatus: 'No, later',
    searchUsers: 'Search users',
    assignedToLabel: 'Assigned to',
    goTocomments: 'Go to comments',
    isFired: 'Is fired',
    candidatesAttribute: 'Add attribute',
    hrInterview: 'HR Interview',
    checkedEnglishLevel: 'Checked English level',
    needPing: 'Need ping',
    testTaskIsDone: 'Test task is done',
    partTime: 'Part time',
    fullTime: 'Full time',
    raisingStar: 'Raising star',
    traineeGrade: 'Trainee',
    juniorGrade: 'Junior',
    middleGrade: 'Middle',
    seniorGrade: 'Senior',
    partTimeLabel: 'Part time',
    fullTimeLabel: 'Full time',

    createVacationSuccess: 'Vacancy has been successfully created',
    pendingStatus: 'Pending',

    labelYes: 'Yes',
    labelNo: 'No',
    labelFire: 'Fire',
    labelCancel: 'Cancel',
    labelStartDate: 'Start date',
    labelYear: 'Select year',
    labelDate: 'Select date',

    teamSection: 'Team',
    candidatesSection: 'Candidates',

    RecruitmentHRSection: 'Recruitment & HR',
    remindersSection: 'Reminders',
    vacanciesSection: 'Vacancies',
    clientsSection: 'Clients',
    hrStatisticsSection: 'Statistics',
    withoutTags: 'Without tags',

    loadMore: 'Load More',

    filterByStatus: 'By status',
    filterByName: 'By Full name',
    filterBySpecialization: 'By Specialization',
    filterByGrade: 'By grade',
    filterByVacancy: 'By vacancy',
    filterByVacancyStatus: 'By vacancy status',
    filterByCandidateAttribute: 'By attribute',
    filterByRefusalReason: 'By refusal reason',
    allStatusesFilter: 'All',
    filterAllCandidates: 'All candidates',

    removeFilter: 'Remove filter',
    removeVacancyCandidate: 'Remove vacancy',

    noDataWarning: 'No data',
    noVacancySelected: 'Please select a vacancy or specialization',
    noCandidates: 'Candidates not found',

    allStatuses: 'All statuses',
    newStatus: 'New request',
    preScreeningStatus: 'Pre-screening',
    onPendingStatus: 'Under consideration of Leads',
    hrInterviewStatus: 'HR interview',
    suitableStatus: 'Сandidate is suitable',
    approvedByLeadStatus: 'Approved by Lead',
    testStatus: 'Test task',
    techInterviewStatus: 'Technical interview',
    preSuitableStatus: 'Pre-suitable',
    customerInterviewStatus: 'Customer interview',
    notSuitableStatus: `Not suitable`,
    sentOfferStatus: 'Offer sent',
    acceptedOfferStatus: 'Offer accepted',
    onboardingStatus: 'Onboarding',
    inTeamStatus: 'Team member',
    blacklistStatus: 'In blacklist',
    needPingStatus: 'Need ping',
    contractorStatus: 'Contractor',
    doneStatus: 'Done',

    hrInterviewAttribute: 'HR Interview',
    englishLevelAttribute: 'Checking English level',
    needPingAttribute: 'Need Ping',
    testTaskAttribute: 'Test Task is done',
    partTimeAttribute: 'Part time',
    fullTimeAttribute: 'Full time',
    starAttribute: 'Raising star',

    weekTimeTraked: 'Please validate and send your time for week',
    docsNotFound: 'Docs not found',

    editUserSuccessMsg: 'User has been successfully updated',
    changeUserEmailErrorMsg: 'User with this email already exists',
    cantSaveReportMsg: "No data available. Can't save report",
    specializationCreateSuccessMsg: 'Specialization has been successfully created',
    specializationUpdateSuccessMsg: 'Specialization has been successfully updated',
    specializationDeleteSuccessMsg: 'Specialization has been successfully deleted',
    channelCreateSuccessMsg: 'Channel has been successfully created',
    channelUpdateSuccessMsg: 'Channel has been successfully updated',
    channelDeleteSuccessMsg: 'Channel has been successfully deleted',

    userTitle: 'User',

    deleteModalSubmit: 'Delete',
    deleteModalDeny: 'Cancel',

    JanMonth: 'Jan',
    FebMonth: 'Feb',
    MarMonth: 'Mar',
    AprMonth: 'Apr',
    MayMonth: 'May',
    JunMonth: 'Jun',
    JulMonth: 'Jul',
    AugMonth: 'Aug',
    SepMonth: 'Sep',
    OctMonth: 'Oct',
    NovMonth: 'Nov',
    DecMonth: 'Dec',

    goBackBtn: 'Go back',
    goBackBtnReset: 'Go back to',
    userDetailsTab: 'Details',
    userCommentsTab: 'Comments',
    userFinancialInfoTab: 'Financial Info',
    userDocsAndLinksTab: 'Docs and Links',
    createCommentButton: 'Create comment',
    editCommentButton: 'Edit comment',

    candidateReviewUserComment: 'Candidate Review',
    englishTestUserComment: 'English Test',
    testTaskUserComment: 'Test task',
    interviewReviewUserComment: 'Interview review',
    refusalReasonUserComment: 'Refusal Reason',
    candidateHiredUserComment: 'Candidate Hired',
    otherUserComment: 'Other',

    noExperienceRefusalReason: 'No working experience',
    lowEnglishRefusalReason: 'Low level of English',
    requiresHighSalaryRefusalReason: 'Requires overhigh salary',
    noContactRefusalReason: 'Lost contact',
    workInOfficeRefusalReason: 'Wants to work in office',
    interviewFailedRefusalReason: 'Failed interview',
    offerDeclinedRefusalReason: 'Has declined the offer',
    toxicRefusalReason: 'Toxic person',
    overratedOwnCapabilitiesReason: 'Overrated own capabilities ',

    userCommentEditSuccess: 'User comment has been successfully updated',
    userCommentCreateSuccess: 'User comment has been successfully created',
    commentTitleLabel: 'Comment title',
    refusalReasonLabel: 'Refusal reason',
    commentDescriptionLabel: 'Comment description',
    reminderDescriptionLabel: 'Type your reminder description here',
    anonymouslyStatusLabel: 'Anonymously',
    userCommentDeleteTitle: 'Are you sure you want to delete comment?',
    userCommentDeleteSuccess: 'Comment has been successfully deleted',

    userReviewsTab: 'Reviews',
    anonymousAuthor: 'Anonymous author',
    suggestedToStartDoingTitle: 'What collegue should start doing',
    suggestedToStopDoingTitle: 'What collegue should stop doing',
    suggestedToContinueDoingTitle: 'What collegue is good at and should continue doing',
    userReviewDeleteTitle: 'Are you sure you want to delete a review?',
    createReviewButton: 'Create review',
    editReviewButton: 'Edit review',
    reviewCreateSuccess: 'The review has been created',
    reviewEditSuccess: 'The review has been edited',
    reviewDeleteSuccess: 'The review has been deleted',

    userRemindersTab: 'Reminders',
    createReminderLabel: 'Create reminder',
    createReminderText: 'Enter the date and description of the reminder',
    editReminderLabel: 'Edit reminder',
    reminderAuthor: 'Author',
    reminderUser: 'User',
    reminderDate: 'Date',
    reminderDescription: 'Description',
    createReminderSuccess: 'The reminder has been created',
    editReminderSuccess: 'The reminder has been edited',
    deleteReminderSuccess: 'The reminder has been successfully deleted',
    deleteReminderTitle: 'Are you sure you want to delete the reminder?',
    goToReminders: "Go to candidate's reminders",
    noReminders: 'no reminders',

    candidateChennel: 'Candidate channel',
    vacancyStatus: 'Vacancy status',
    vacancyTitle: 'Vacancy',
    vacancySpecialization: 'Specialization',
    additionRole: 'Addition Roles',
    vacancyGrade: 'Grade',
    vacancyDescription: 'About vacancy',
    vacancyStartDate: 'Start date',
    vacancyEndDate: 'End date',
    vacancyOpen: 'Open',
    vacancyEdit: 'Draft',
    vacancyTemplate: 'Template',
    vacancyCancelled: 'Cancelled',
    vacancyCloseSuccessful: 'Closed successfully',
    vacancyCloseSuccessfulSubtitle:
      'Are you sure you want to close this vacancy? This vacancy will be moved to the closed successfully status.',
    closeVacancyButton: 'Close vacancy',
    vacancyCloseUnSuccessful: 'Closed unsuccessfully',
    vacancyCloseUnSuccessfulSubtitle:
      'Are you sure you want to close this vacancy? This vacancy will be moved to the closed unsuccessfully status.',
    vacancyArchive: 'Archive',
    createVacancy: 'Create vacancy',
    createVacancyFromTemplate: 'Create vacancy from template',
    editVacancy: 'Edit vacancy',
    editVacancySubtitle: 'Are you sure you want to edit vacancy?',
    editStatusVacancy: 'Change vacancy status',
    openTheVacancy: 'Are you sure you want to open the vacancy?',
    closeTheVacancy: 'Are you sure you want to close the vacancy?',
    confirmOpenVacancy: 'Open',
    confirmCloseVacancy: 'Close',
    vacancyEditSuccess: 'Vacancy has been successfully updated!',
    vacancyStatusSuccess: 'Vacancy status has been successfully updated!',
    backToVacancies: 'Back to vacancies',
    allStatusesVacancies: 'All statuses',
    deleteVacancy: 'Delete vacancy',
    deleteVacancyConfirm: 'Are you sure you want delete this vacancy?',
    deleteVacancySuccess: 'Vacancy status has been successfully deleted!',
    chooseTemplate: 'Choose a template',
    vacancyDetailsTab: 'Details',
    vacancyCandidatesTab: 'Candidates',
    candidatesTab: 'Board',
    candidatesListTab: 'List',
    noVacancyCandidates: 'Candidates without vacancy',
    vacancyFunnelOfCandidatesTab: 'Statistics',
    vacancyCandidatesNoData: 'There are no candidates for this vacancy yet.',
    noCandidateHistory: 'There are no history for this candidate yet.',
    goToVacancyButton: 'Go to Vacancy',

    createChannelTitle: 'Add channel',
    editChannelTitle: 'Edit channel',
    channelSelectTitle: 'Choose channel',
    deleteChannelConfirm: 'Choose channel which you want to delete.',

    createSpecializationTitle: 'Add specialization',
    createSpecializationButton: 'Add',
    editSpecializationTitle: 'Edit specialization',
    specializationSelectTitle: 'Choose specialization',
    editSpecializationButton: 'Edit',
    deleteSpecializationButton: 'Delete',
    deleteSpecializationConfirm: 'Choose specialization which you want to delete.',
    specializationName: 'Name',
    newSpecializationName: 'New name',

    statisticsVacanciesTitle: 'Closing vacancies statistics',
    closedSuccessfullyField: 'Closed successfully',
    closedUnSuccessfullyField: 'Closed unsuccessfully',
    hiringAndFiringStatistics: 'Hiring and firing statistics',
    hiredEmpoleesLabel: 'Hired employees',
    firedEmpoleesLabel: 'Fired employees',
    dateOfHiring: 'Date of hiring',
    dateOfDismissal: 'Date of dismissal',
    statisticsFunnelOfCandidatesTitle: 'Funnel of candidates',
    vacancyStatusLabel: 'Vacancy status',
    vacancyLabel: 'Select vacancy',
    suitableStatusCandidate: 'Suitable',
    checkingEnglishStatus: 'Checking English',
    underLeadsConsideration: 'Leads consideration',

    hireCandidate: 'Hire a candidate',
    canditateEditStatus: 'Edit status',

    fireAnEmployee: 'Fire an employee',
    roleTitle: 'Choose role',
    fireEmployee: 'Are you sure you want to fire employee?',
    firedEmployeeSuccessMsg: 'Employee has been fired',
    hiredCandidateSuccessMsg: 'Candidate has been hired',
    deleteEmployeeConfirmation: 'Are you sure you want to delete this candidate from statistics?',
    deleteEmployeeSuccess: 'Canidate was deleted successfully',
    changeCandidateStatusSuccessMsg: 'Candidate status has been successfully updated',
    changeCandidateStatusErrorMsg: 'Candidate status updating failed',
    usersDoesNotExist: 'Users does not exist',

    documentNameCell: 'Document Name',
    documentTypeCell: 'Type',
    documentUploadedCell: 'Uploaded at',
    documentCreateSuccessMsg: 'Document saved successfully',
    documentUpdateSuccessMsg: 'Document successfully updated ',
    documentUpdateFailureMsg: 'Document saving failed',
    documentCreateFailureMsg: 'Document updating failed',
    deleteDocumentConfirm: 'Are you sure you want to delete this document?',
    deleteDocumentSuccess: 'Document has been successfully deleted',
    deleteDocumentFailure: 'Document deleting failure',
    documentMenuFileLabel: 'Add file',
    documentMenuLinkLabel: 'Add link',
    documentModalName: 'Name',
    documentModalDescription: 'Description',
    documentModalType: 'Type',
    documentModalVisibility: 'Visibility',
    documentModalLink: 'Link',
    documentModalAdd: 'Add document',
    documentModalEdit: 'Edit document',
    documentModalAddFile: 'Add file',
    documentModalAddLink: 'Add link',
    documentModalEditFile: 'Edit file',
    documentModalEditLink: 'Edit link',
    documentsDoesntExist: 'There are no documents',
    documentDescriptionCell: 'Description',
    editUserVacancyInfo:
      "It is not possible to change the vacancy of a candidate with the status 'not suitable' or 'done'",

    teamProfileLinkText: 'Profile',
    teamInvitePeopleText: 'Invite members',
    teamSecurityAndPermissionsText: 'Security & Permissions',
    userSettingsText: 'My settings',
    userHelpLinkText: 'Help',
    profilePageTitle: 'Profile',
    addTeamText: 'Add new team',
    teamList: 'Team list',

    welcomeTeamCreationTitle: 'Welcome',
    welcomeTeamCreationSubtitle: 'Lets create your first team',
    teamNameInputLabel: 'Team name',
    teamNameFieldTitle: 'Name your team',
    memberFullname: 'Full name (Latin)',
    membersEmailsInputLabel: 'Member email',
    membersEmailsInputHelperText: "Press 'ENTER' to add",
    membersEmailsFieldTitle: 'Invite members to your team',
    searchEngineButtonText: 'Search engine (Google, Bing, etc.)',
    socialMediaButtonText: 'Social media',
    referralButtonText: 'Referral',
    blogButtonText: 'Blog or publication',
    otherChannelLabel: 'Other channel',
    sourceFieldTitle: 'How did you find out about OmiTeam?',
    justMeButtonText: 'Just me',
    teamSizeFieldTitle: 'Please select a size of your team:',
    nextStepButtonText: 'Next step',
    backButtonText: 'Back',
    createTeamFinishTitle: 'Congratulations!',
    createTeamFinishSubtitle: 'You’ve created your first team',
    createTeamButtonText: 'Continue to OmiTeam',
    createTeamSuccessNotification: 'Your team has been successfully created!',
    nonUniqueEmailError: 'This email has already added',

    teamOwnerRole: 'Owner',
    teamAdminRole: 'Admin',
    userRole: 'Member',
    userRoleSelectText: 'Select a member role',
    sendInviteBtnText: 'Send invite',
    membersInvitedSuccess: 'Members have been invited successfully!',
    cancelInviteSuccess: 'Invitation has been canceled successfulty',
    emptyEmailsError: 'Add member email before sending invitation!',

    logoutModalTitle: 'Are you sure you want to log out?',

    renameTeamTitle: 'Re-name your team',
    teamSettingsTitle: 'Team settings',
    teamRenameText: 'Re-name team',
    teamDeleteText: 'Delete team',
    renameTeamSuccess: 'Team has re-named successfully',
    deleteTeamSuccess: 'Team has deleted successfully',
    deleteModalTitle: 'Team delete',
    deleteModalSubtitleQuestion: 'Are you sure you want to delete your team',
    deleteModalSubtitleExplaining:
      'If you delete it, all data will be lost, to confirm this process enter your name and approve this process.',
    approveDeleteLabel: 'Approve delete',
    userNameInputLabel: 'Enter your name',
    nonCorrectUsernameError: 'Provided name is not your username',

    archiveEmployeeText: 'Archive employee',
    archiveEmployeeModalApproving: 'Are you sure you want to archive your employee',
    archiveEmployeeModalConsequences: 'If you archive it, the person will not be able to use this product.',
    archiveButton: 'Archive',
    searchMembersLabel: 'Search by Name or Email address',
    unarchiveEmployeeText: 'Unarchive employee',
    unarchiveEmployeeModalApproving: 'Are you sure you want to unarchive your employee',
    unarchiveEmployeeModalConsequences:
      'If you unarchive worker, the person will be able continue to use this product.',
    unarchiveButton: 'Unarchive',
    resendInvitationText: 'Re-send invitation',
    cancelInvitationText: 'Cancel invitation',
    cancelInvitationModalApproving: 'Are you sure you want to cancel invitation to',
    cancelInvitationModalConsequences: 'If you cancel it, the person will not be able to join your team.',
    nameLabel: 'Name',
    nameLabelClient: 'Name',
    roleLabel: 'Role',
    statusLabel: 'Status',
    allMembersLabel: 'All Members',
    activeEmployeeStatus: 'Active Users',
    pendingEmployeeStatus: 'Pending',
    archivedEmployeeStatus: 'Archived',

    activeMembersStatus: 'Active',
    pendingMembersStatus: 'Pending',
    archivedMembersStatus: 'Archived',

    emptyPageSubtitle: 'The board looks empty, invite your first workers to this space',
    changeEmployeeRoleSuccess: 'Employee role has changed successfully',

    archiveEmployeeSuccess: 'Employee has been archived successfully',
    unarchiveEmployeeSuccess: 'Employee has been unarchived successfully',
    actionFailed: 'You have chose wrong action',
    notFoundPageTitle: 'Whoops.. Page not found!',
    notFoundPageDescription: 'We are tried really hard, but we could not find the page you are trying to reach.',
    backToHome: 'Back to Home',
    decisionMaker: 'Decision maker',
    latestUpdateTooltip: 'Latest update',
    clientTags: 'Client tags',
    viewProfile: 'View profile',
    searchByName: 'Search by name',
    addDecisionMaker: 'Add decision maker',
    editClient: 'Edit client',
    archiveClient: 'Archive client',
    unarchiveClient: 'Unarchive client',
    openVacancy: 'Open vacancy',
    documentsTitle: 'Documents',
    nameProfile: '{{name}} profile',
    createClientPageTitle: 'Create client',
    createClient: 'Create client',
    editClientButton: 'Save changes',
    mySettings: 'My settings',
    changePassword: 'Change password',
    profilePictrue: 'Profile picture',
    uploadNewImage: 'Upload new image',
    imageRestrictions: 'All .jpg, .png files up to 10 MB are supproted.',
    upload: 'Upload',
    deleteImage: 'Delete image',
    latin: 'Latin',
    local: 'Local',
    additionalInfo: 'Additional information',
    saveChanges: 'Save changes',
    clearAll: 'Clear all',
    clickToUpload: 'Click to upload',
    close: 'Close',
    save: 'Save',
    deleteAccounts: 'Delete account',
    areYouSure:
      'Are you sure you want to delete your account {{name}}? If you delete it, all data will be lost forever for your account.',
    changePasswordDescription:
      'The new password must not be the same as the old one, and must contain uppercase and lowercase letters, special characters, and numbers',
    imageIsTooLarge: 'This image is too large.',
    notSuitableFormat: 'This format is not suitable.',
    onlyLettersAreAllowed: 'Only letters, hyphens, and spaces are allowed',
    onlyLatinLettersAreAllowed: 'Only latin letters, hyphens, and spaces are allowed',
    enterCorrectMobilePhone: 'Enter correct mobile phone',
    enterCorrectEmail: 'Enter correct email',
    serverErrorText: 'Service Temporarily Unavailable!',
    serverErrorTextDescription:
      'Sorry for inconvenience, but it looks like something went wrong with a server. Please retry after some time.',
    serverErrorTextAuthor: '— IT Support Team',
    clientAvatar: 'Client avatar',

    addNewSpecializationModalTitle: 'Add new specialization',
    addNewSpecializationModalSubtitle: 'Add a new specialization that you can use in your new job vacancies.',

    editSpecializationModalTitle: 'Edit existed specialization',
    editSpecializationModalSubtitle:
      'Edit existing specializations with one click. All existing vacancies will be updated according to the changes.',
    deleteSpecializationModalTitle: 'Delete specialization',
    deleteSpecializationModalSubtitle:
      'Are you sure you want to delete the specialization? This will lead to changes in existing vacancies.',
    specializationLabel: 'Specialization',
    oldSpecializationLabel: 'Old specialization name',
    newSpecializationLabel: 'New specialization name',
    chooseSpecializationLabel: 'Choose specialization',
    nonUniqueSpecializationError: 'This specialization has already created',
    emptySpecializationsError: 'Create at least 1 specialization before submitting',
    addNewCandidateTagModalTitle: 'Add new candidate tag',
    addNewCandidatetagModalSubtitle: 'Add a new candidate tag that you can use in your new candidates.',
    editCandidateTagModalTitle: 'Edit existed candidate tag',
    editCandidateTagModalSubtitle:
      'Edit existing candidate tag with one click. All existing candidate will be updated according to the changes.',
    deleteCandidateTagModalTitle: 'Delete candidate tag',
    deleteCandidateTagModalSubtitle:
      'Are you sure you want to delete the candidate tag? This will lead to changes in existing candidates.',
    emptyTagsError: 'Create at least 1 tag before submitting',
    emptyChannelsError: 'Create at least 1 channel before submitting',
    nonUniqueTagError: 'This tag has already created',
    nonUniqueChannelError: 'This channel has already created',
    candidateTagLabel: 'Candidate tag',
    tagsCreateSuccessMsg: 'Tags have been successfully created',
    oldTagLabel: 'Old tag name',
    newTagLabel: 'New tag name',
    tagUpdateSuccessMsg: 'Tag has been successfully updated',
    tagDeleteSuccessMsg: 'Tag has been successfully deleted',
    chooseTagLabel: 'Choose tag',

    createCandidatePagTitle: 'Create candidate',
    mandatoryInformationTitle: 'Mandatory information',
    cvAndLinksTitle: 'CV and links',
    nonUniqueLinkError: 'Link has already added',
    nonValidLinkError: 'Link is not valid',
    moscovienLinkError: 'Links containing russian(Moscovien) domain are prohibited!',
    additionalInfoTitle: 'Additional information',
    clearForm: 'Clear form',
    dropzoneTitle: 'Click to upload or drag and drop',
    dropzoneSubtitle: 'All .pdf, .docx files up to 20 MB are supproted.',
    fileSizeError: 'Files size is too big',
    fileTypeError: 'File type is not supported',
    incorrectPhoneNumber: 'Incorrect phone number',
    filtersModalTitle: 'Filters',
    specializationFilterLabel: 'By specialization',
    gradeFilterLabel: 'By grade',
    clientFilterLabel: 'By client',
    vacancyFilterLabel: 'By vacancy',
    vacancyStatusFilterLabel: 'By vacancy status',
    candidateName: 'Candidate name',
    searchCandidateLabel: 'Search by Name',
    notSuitableText: 'Not suitable',
    hireCandidateModalTitle: 'Hire candidate',
    hireCandidateModalSubtitle: 'Set for which vacancy candidate hired',
    rejectionCandidateModalTitle: 'Rejection of a candidate',
    rejectionCandidateModalSubtitle: 'Specify the reason why you are rejecting this candidate',
    rejectTitleLabel: 'Reject title',
    rejectReasonLabel: 'Reject reason',
    noDataToDisplayTitle: 'Whoops.. No data to display',
    noDataToDisplaySubtitle:
      "It looks like you don't have any candidates created yet, create your first one right now!",
    noRemindersToDisplaySubtitle:
      "It looks like you don't have any reminders created yet, create your first one right now!",
    noClientsToDisplaySubtitle:
      "It looks like you don't have any clients created yet, create your first one right now!",
    noClientsVacanciesToDisplaySubtitle:
      "It looks like you don't add client to any vacancies yet, add to first one right now!",
    noCandidatesToDisplaySubtitle:
      "It looks like you don't have any candidates with this vacancy yet, add this vacancy to some candidates right now!",
    candidatesActivitiesTab: 'Activities',
    candidatesCommentTitle: 'Comment title',
    noAnyActivitiesSubtitle: "It looks like this candidate don't have any activities yet.",
    noAnyRemindersSubtitle: "It looks like this candidate don't have any reminders yet.",

    addNewCommentActivityLabel: 'add new comment',
    updateCommentActivityLabel: 'update comment',
    deleteCommentActivityLabel: 'delete comment',
    changeStatusActivityLabel: 'change status',
    addNewReminderActivityLabel: 'add new reminder',
    updateReminderActivityLabel: 'update reminder',
    deleteReminderActivityLabel: 'delete reminder',

    editCandidateReminderText: 'Edit reminder',
    deleteCandidateReminderText: 'Delete reminder',
    deleteReminderModalSubtitle: 'Are you sure you want to delete this reminder?',
    editReminderModalSubtitle: 'Change reminder for this candidate',
    updateDate: 'Update date',
    createCandidateReminderSuccessMsg: 'Candidate reminder has been successfully created',
    updateCandidateReminderSuccessMsg: 'Candidate reminder has been successfully updated',
    deleteCandidateReminderSuccessMsg: 'Candidate reminder has been successfully deleted',
    profileText: 'profile',
    editCandidateText: 'Edit profile',
    hireCandidateText: 'Hire candidate',
    rejectCandidateText: 'Not suitable',
    availableCandidateText: 'Available from',
    shortAvailableCandidateText: 'AVAILABLE',
    unavailableCandidateText: 'Unavailable from',
    shortUnavailableCandidateText: 'UNAVAILABLE',
    candidateCVTitle: 'Candidate CV',
    createCandidateCommentSuccessMsg: 'Candidate comment has been successfully created',
    updateCandidateCommentSuccessMsg: 'Candidate comment has been successfully updated',
    deleteCandidateCommentSuccessMsg: 'Candidate comment has been successfully deleted',
    editCandidateCommentText: 'Edit comment',
    deleteCandidateCommentText: 'Delete comment',
    deleteCommentModalSubtitle: 'Are you sure you want to delete this comment?',
    editCommentModalSubtitle: 'Change comment for this candidate',
    noStatus: 'No status',
    clientInformation: 'Client information',
    clientWebsite: 'Client website',
    clientChannel: 'Client channel',
    docsAndLinks: 'Documents and links',
    addNewClientTagModalTitle: 'Add new client tag',
    addNewClientTagModalSubtitle: 'Add a new client tag that you can use in your new clients.',
    editClientTagModalTitle: 'Edit existed client tag',
    editClientTagModalSubtitle:
      'Edit existing client tag with one click. All existing client will be updated according to the changes.',
    deleteClientTagModalTitle: 'Delete client tag',
    deleteClientTagModalSubtitle:
      'Are you sure you want to delete the client tag? This will lead to changes in existing clients.',
    clientTagLabel: 'Client tag',
    nonAddedToListError: "Add to list by click on '+' at the end of field",
    addNewClientChannelModalTitle: 'Add new client channel',
    addNewClientChannelModalSubtitle: 'Add a new client channel that you can use in your new clients.',
    editClientChannelModalTitle: 'Edit existed client channel',
    editClientChannelModalSubtitle:
      'Edit existing client channel with one click. All existing client will be updated according to the changes.',
    deleteClientChannelModalTitle: 'Delete client channel',
    deleteClientChannelModalSubtitle:
      'Are you sure you want to delete the client channel? This will lead to changes in existing clients.',
    clientChannelLabel: 'Client channel',
    oldChannelLabel: 'Old channel',
    newChannelLabel: 'New channel',
    editChannelSuccesMsg: 'Channel edited successfully',
    chooseChannelLabel: 'Choose channel',
    createClientSuccessMsg: 'Client has been successfully created',
    updateClientSuccessMsg: 'Client has been successfully updated',
    availabilityLabel: 'Availability',
    changeClientStatusSuccessNotification: 'Client status has been successfully changed',
    searchByNameLabel: 'Search by Name',
    rejectCandidateSuccessMsg: 'Candidate has rejected successfully',
    hireCandidateSuccessMsg: 'Candidate has hired successfully',
    forVacancyText: 'for this vacancy',
    nonValidDate: 'Your date is not valid',
    nonValidMinDate: 'Please select a date from today',
    nonValidMaxDate: 'Please select a date up to today',

    createCandidateTagTitle: 'Create candidate',
    reminderTitle: 'Reminders',
    reminderLabel: 'Reminder',
    notificationTitle: 'Notifications',
    viewReminderLinkText: 'View reminder',
    markAllAsReadText: 'Mark all as read',
    noRemindersSubtitle: 'You have no reminders right now. Come back later.',
    selectVacancyModalTitle: 'Select a vacancy',
    selectVacancyModalSubtitle:
      'To change the status of a candidate, first select a vacancy in the filters, then you will be able to change the status of this candidate.',
    vacancyStatusModalTitle: 'Vacancy was closed',
    vacancyStatusModalSubtitle: 'You can not change status of candidate if vacancy was closed or cancelled',
    searchVacancyLabel: 'Search by Name',
    createVacancyButton: 'Create vacancy',
    createTemplateButton: 'Create template',
    allVacanciesLabel: 'All vacancies',
    vacancyTableHeaderLabel: 'Vacancy',
    clientLabel: 'Client',
    vacancyActiveDays: 'Days passed',
    endDateLabel: 'End date',
    noVacanciesToDisplaySubtitle:
      "It looks like you don't have any vacancies created yet, create your first one right now!",
    publishVacancyText: 'Publish vacancy',
    publishVacancyTextSubtitle:
      'Are you sure you want to publish this vacancy? It will be counted in the general statistics and cannot be deleted.',
    publishVacancyButton: 'Publish',
    deleteVacancyText: 'Delete vacancy',
    deleteVacancyTextSubtitle:
      'Are you sure you want to delete this vacancy? All information about this vacancy will be lost.',
    deleteVacancyButton: 'Delete vacancy',
    closeVacancySuccessfulyText: 'Close successfuly',
    closeVacancyUnsuccessfulyText: 'Close unsuccessfuly',
    copyVacancyText: 'Copy vacancy',
    copyVacancyTextSubtitle:
      'Are you sure you want to copy this vacancy? The copy will be transferred to the "Draft" status, and all candidates who are attached to this vacancy will be copied.',
    copyVacancyButton: 'Copy',
    cancelVacancyText: 'Cancel vacancy',
    cancelVacancySubText:
      'Are you sure you want to cancel this vacancy? This vacancy will be moved to the canceled status, the vacancy will not be taken into account in the statistics.',
    publishVacancySuccess: 'Vacancy has been published successfuly',
    closeVacancySuccessfulySuccess: 'Vacancy has been closed successfuly',
    closeVacancyUnsuccessfulySuccess: 'Vacancy has been closed unsuccessfuly',
    cancelVacancySuccess: 'Vacancy has been canceled successfuly',
    copyVacancySuccess: 'Vacancy has been copied successfuly',
    vacancyInformationTitle: 'Vacancy information',
    vacancyNameField: 'Vacancy name',
    templateField: 'Template',
    clientField: 'Client name',
    chooseGradeField: 'Choose grade',
    assignedToField: 'Assigned To',
    specializationField: 'Specialization',
    aboutVacancyField: 'About vacancy',
    createAsTemplateLabel: 'Create as template',
    editVacancySuccessMsg: 'Vacancy has been changed successfuly',
    createVacancySuccessMsg: 'Vacancy has been created successfuly',
    editVacancyButton: 'Save changes',
    detailsTabText: 'Details',
    decisionMakersTabText: 'Decision makers',
    vacanciesTabText: 'Vacancies',
    candidatesTabText: 'Candidates',
    hiredCandidateLabel: 'Hired candidate',
    availabilityFilterLabel: 'By availability',
    availableLabel: 'Yes',
    unavailableLabel: 'No',
    createdBy: 'Created by',
    create: 'Create',
    remindersDate: 'Reminders date',

    incorrectName: 'Incorrect name',
    tooLongName: 'Entered name is too long (max 80 symbols)',
    incorrectLink: 'Incorrect link',
    incorrectCity: 'Incorrect city name',
    acceptInviteSuccess: 'Invitation has been accepted successfuly',
    createTeamTooltipText: 'Create a team',

    addDicisionMakerTitle: 'Invite decision maker to your client',
    addDicisionMakerSubtitle:
      'You can add several decision makers to a client, and they will have access to vacancies and candidates.',
    addDicisionMakerButton: 'Send invite',

    dicisionMakerNameTitle: 'Name',
    dicisionMakerEmailTitle: 'Email',
    dicisionMakerDateTitle: 'Date',

    dicisionMakerReSendInvitation: 'Re-send invitation',
    dicisionMakerCancelInvite: 'Cancel invite',
    dicisionMakerArchiveWorker: 'Archive worker',
    dicisionMakerUnArchiveWorker: 'Unarchive worker',

    dicisionMakerEmail: 'Email',
    dicisionMakerData: 'Data',
  },
};
