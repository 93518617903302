import { getAllClients } from '../../../actions/clients';
import { store } from '../../../index';

export function* onChangeClientStatus() {
  const { dispatch } = store;

  dispatch(getAllClients({}));

  yield true;
}
