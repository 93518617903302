import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';
import { getErrorMessage } from '../../helpers/getErrorMessage';

export const GET_COMMENTS_FOR_CANDIDATE_SUCCESS = createAction('candidateComments/GET_COMMENTS_FOR_CANDIDATE.SUCCESS');
export const getCommentsForCandidate = (candidateId: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `candidates/comments/candidate/${candidateId}`,
    });
    dispatch(GET_COMMENTS_FOR_CANDIDATE_SUCCESS(data));
  } catch (err) {}
};

type CreateCandidateCommentActionType = {
  candidateId: string;
  commentData: {
    title: string | null;
    refusalReason?: string;
    description: string;
  };
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const CREATE_CANDIDATE_COMMENT_SUCCESS = createAction('candidateComments/CREATE_CANDIDATE_COMMENT.SUCCESS');
export const CREATE_CANDIDATE_COMMENT_FAILURE = createAction('candidateComments/CREATE_CANDIDATE_COMMENT.FAILURE');
export const createCandidateComment =
  ({ candidateId, commentData, onSuccess, onFailure }: CreateCandidateCommentActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `candidates/comments`,
        query: { ...commentData, candidateId },
      });
      dispatch(CREATE_CANDIDATE_COMMENT_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(CREATE_CANDIDATE_COMMENT_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

type UpdateCandidateCommentActionType = {
  commentId: string;
  commentData: {
    title: string;
    refusalReason?: string;
    description: string;
  };
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const UPDATE_CANDIDATE_COMMENT_SUCCESS = createAction('canddiateComments/UPDATE_CANDIDATE_COMMENT.SUCCESS');
export const UPDATE_CANDIDATE_COMMENT_FAILURE = createAction('candidateComments/UPDATE_CANDIDATE_COMMENT.FAILURE');
export const updateCandidateComment =
  ({ commentId, commentData, onSuccess, onFailure }: UpdateCandidateCommentActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `candidates/comments/${commentId}`,
        query: commentData,
      });
      dispatch(UPDATE_CANDIDATE_COMMENT_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(UPDATE_CANDIDATE_COMMENT_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

type DeleteCandidateCommentActionType = {
  commentId: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const DELETE_CANDIDATE_COMMENT_SUCCESS = createAction('candidateComments/DELETE_CANDIDATE_COMMENT.SUCCESS');
export const DELETE_CANDIDATE_COMMENT_FAILURE = createAction('candidateComments/DELETE_CANDIDATE_COMMENT.FAILURE');
export const deleteCandidateComment =
  ({ commentId, onSuccess, onFailure }: DeleteCandidateCommentActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `candidates/comments/${commentId}`,
      });
      dispatch(DELETE_CANDIDATE_COMMENT_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(DELETE_CANDIDATE_COMMENT_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const CLEAR_CANDIDATE_COMMENTS = createAction('candidates/CLEAR_CANDIDATE_COMMENTS');
export const clearCandidateComments = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_CANDIDATE_COMMENTS());
};
