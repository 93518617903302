import { createAction } from '@reduxjs/toolkit';
import { roles } from '../../assets/roles';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { ApiService } from '../middleware/api';
import { AppDispatch } from '../index';
import { IUser } from '../../types/api/users';

type FetchAllUsersType = {
  page?: number;
  offset?: number;
  role?: string;
  addRole?: string;
  skipRole?: string;
  status?: string;
  nameFilter?: string;
  specializationIdArrayFilter?: string;
  activeStatus?: string;
  grade?: string;
  vacancy?: string;
  noVacancy?: string;
  vacancyStatus?: string;
  refusalReason?: string;
  assignee?: string;
  candidateAttribute?: string;
};
export const GET_ALL_USERS_SUCCESS = createAction('users/GET_ALL_USERS.SUCCESS');
export const GET_ALL_USERS_PENDING = createAction('users/GET_ALL_USERS.PENDING');
export const GET_ALL_USERS_FAILURE = createAction('users/GET_ALL_USERS.FAILURE');

export const fetchAllUsers =
  (
    {
      page,
      offset,
      role,
      addRole,
      skipRole = '',
      status,
      nameFilter,
      activeStatus,
      grade,
      vacancy,
      noVacancy,
      vacancyStatus,
      refusalReason,
      assignee,
      candidateAttribute,
    }: FetchAllUsersType,
    onSuccess: (data: any) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(GET_ALL_USERS_PENDING());
    try {
      const pageFilter = page ? `page=${page}` : '';
      const offsetFilter = offset ? `&offset=${offset}` : '';
      const allRoles = Object.keys(roles).join(',');
      const filterByRole = role === 'ALL' ? allRoles : role;
      const filterByAdditionalRole = addRole ? `&addRole=${addRole}` : '';
      const filterByRoleExceptOne = skipRole ? `&skipRole=${skipRole}` : '';
      const filterByStatus = status ? `&status=${status}` : '';
      const filterByName = nameFilter ? `&name=${nameFilter}` : '';
      const filterByActive = activeStatus ? `&activeStatus=${activeStatus}` : '';
      const filterByGrade = grade ? `&grade=${grade}` : '';
      const filterByVacancy = vacancy ? `&vacancy=${vacancy}` : '';
      const filterByVacancyStatus = vacancyStatus ? `&vacancyStatus=${vacancyStatus}` : '';
      const filterNoVacancy = noVacancy ? `&noVacancy=${noVacancy}` : '';
      const filterByRefusalReason = refusalReason ? `&refusalReason=${refusalReason}` : '';
      const filterByAssignee = assignee ? `&assignee=${assignee}` : '';
      const filterByAttribute = candidateAttribute ? `&candidateAttribute=${candidateAttribute}` : '';

      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `users?${pageFilter}${offsetFilter}&role=${filterByRole}${filterByName}${filterByActive}${filterByStatus}${filterByGrade}${filterByRoleExceptOne}${filterByVacancy}${filterByVacancyStatus}${filterNoVacancy}${filterByRefusalReason}${filterByAssignee}${filterByAdditionalRole}${filterByAttribute}`,
      });
      dispatch(GET_ALL_USERS_SUCCESS(data));
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(GET_ALL_USERS_FAILURE(errorMessage));
    }
  };

export const GET_RECRUITERS_SUCCESS = createAction('users/GET_RECRUITERS.SUCCESS');
export const fetchAllRecruiters = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users?role=RECRUITER',
    });
    dispatch(GET_RECRUITERS_SUCCESS(data));
  } catch (err) {}
};

export const GET_ACTIVE_USER_ID_SUCCESS = createAction('users/GET_ACTIVE_USER_ID.SUCCESS');
export const fetchActiveUserId = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users/me',
    });
    dispatch(GET_ACTIVE_USER_ID_SUCCESS(data));
  } catch (err) {}
};

export const GET_USER_EXISTENCE_SUCCESS = createAction('users/GET_USER_EXISTENCE.SUCCESS');
export const getUserExistence =
  (email: string, onFailure: (error: string) => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'users/is-exist',
        qsParams: {
          email: email ?? null,
        },
      });
      dispatch(GET_USER_EXISTENCE_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

export const CREATE_USER_SUCCESS = createAction('users/CREATE_USER.SUCCESS');
export const CREATE_USER_FAILURE = createAction('users/CREATE_USER.FAILURE');
export const createUser =
  (userData: IUser, onSuccess: (data: any) => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `users`,
        query: {
          user: {
            ...userData,
          },
        },
      });
      dispatch(CREATE_USER_SUCCESS(data));
      onSuccess(data);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
      dispatch(CREATE_USER_FAILURE(errorMessage));
    }
  };

export const CHANGE_USER_PASSWORD_SUCCESS = createAction('users/CHANGE_USER_PASSWORD.SUCCESS');
export const CHANGE_USER_PASSWORD_FAILURE = createAction('users/CHANGE_USER_PASSWORD.FAILURE');
export const changeUserPassword =
  (id: string, oldPassword: string, newPassword: string, onSuccess: () => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `users/password/${id}`,
        query: {
          oldPassword,
          password: newPassword,
        },
      });
      dispatch(CHANGE_USER_PASSWORD_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(CHANGE_USER_PASSWORD_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const CHANGE_USER_PARAMETER = createAction('users/CHANGE_USERT_PARAMETER');
export const changeUserParameter = (id: string, parameter: string, value: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: CHANGE_USER_PARAMETER,
    payload: { id, parameter, value },
  });
};

export const DELETE_USER_SUCCESS = createAction('users/DELETE_USER.SUCCESS');
export const deleteUser = (id: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `users/${id}`,
    });
    dispatch(DELETE_USER_SUCCESS(data));
  } catch (err) {}
};

export const INCREASE_USERS_LENGTH = createAction('users/INCREASE_USERS_LENGTH');
export const increaseUsersLength = () => (dispatch: AppDispatch) => {
  dispatch(INCREASE_USERS_LENGTH());
};

export const CHANGE_USER_STATUS_SUCCESS = createAction('users/CHANGE_USER_STATUS.SUCCESS');
export const CHANGE_USER_STATUS_FAILURE = createAction('users/CHANGE_USER_STATUS.FAILURE');
export const changeUserStatus = (id: string, status: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/active/status/${id}`,
      query: {
        status: status,
      },
    });
    dispatch(CHANGE_USER_STATUS_SUCCESS(data));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    dispatch(CHANGE_USER_STATUS_FAILURE(errorMessage));
  }
};
export const CHANGE_USER_ROLE_SUCCESS = createAction('users/CHANGE_USER_ROLE.SUCCESS');
export const CHANGE_USER_ROLE_FAILURE = createAction('users/CHANGE_USER_ROLE.FAILURE');
export const changeUserRole =
  (id: string, user: IUser, onSuccess: () => void, onFailure: (err: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `users/role/${id}`,
        query: user,
      });
      dispatch(CHANGE_USER_ROLE_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(CHANGE_USER_ROLE_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const CHANGE_USER_INFO_SUCCESS = createAction('users/CHANGE_USER_INFO.SUCCESS');
export const CHANGE_USER_INFO_FAILURE = createAction('users/CHANGE_USER_INFO.FAILURE');
export const changeUserInfo =
  (id: string, user: IUser, onSuccess: () => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `users/${id}`,
        query: { ...user, speciality: user.speciality ? user.speciality : null },
      });
      dispatch(CHANGE_USER_INFO_SUCCESS(data));
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(CHANGE_USER_INFO_FAILURE(errorMessage));
      onFailure && onFailure(errorMessage);
    }
  };

export const GET_USERS_STATISTICS_SUCCESS = createAction('users/GET_USERS_STATISTICS.SUCCESS');
export const fetchUsersStatistics = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users/users-statistics',
    });
    dispatch(GET_USERS_STATISTICS_SUCCESS(data));
  } catch (err) {}
};

export const GET_USERS_BIRTHDAYS_SUCCESS = createAction('users/GET_USERS_BIRTHDAYS.SUCCESS');
export const fetchUsersBirthdays = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'users/birthdays',
    });
    dispatch(GET_USERS_BIRTHDAYS_SUCCESS(data));
  } catch (err) {}
};

export const GET_WORK_ANNIVERSARY = createAction('users/GET_WORK_ANNIVERSARY');
export const fetchWorkAnniversary = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: '/users/work-anniversary',
    });
    dispatch(GET_WORK_ANNIVERSARY(data));
  } catch (err) {}
};

export const RESET_USER_PASSWORD_SUCCESS = createAction('users/RESET_USER_PASSWORD.SUCCESS');
export const resetUserPassword = (id: string, password: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/reset-password/${id}`,
      query: { newPassword: password },
    });
    dispatch(RESET_USER_PASSWORD_SUCCESS(data));
  } catch (err) {}
};

export const CHANGE_USER_SUCCESS_MESSAGE = createAction('users/CHANGE_USER_SUCCESS_MESSAGE');
export const changeUserSuccessMessage = (payload) => (dispatch: AppDispatch) => {
  dispatch(CHANGE_USER_SUCCESS_MESSAGE(payload));
};

export const CLEAR_CHANGE_USER_ERROR = createAction('users/CLEAR_CHANGE_USER_ERROR');
export const clearChangeUserError =
  (payload = '') =>
  (dispatch: AppDispatch) => {
    dispatch(CLEAR_CHANGE_USER_ERROR(payload));
  };

export const GET_USER_BY_ID_SUCCESS = createAction('users/GET_USER_BY_ID.SUCCESS');
export const GET_USER_BY_ID_PENDING = createAction('users/GET_USER_BY_ID.PENDING');
export const GET_USER_BY_ID_FAILURE = createAction('users/GET_USER_BY_ID.FAILURE');
export const getUserById = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(GET_USER_BY_ID_PENDING());
    const { data } = await ApiService.apiCall({
      endpoint: `users/${userId}`,
    });

    dispatch(GET_USER_BY_ID_SUCCESS(data));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    dispatch(GET_USER_BY_ID_FAILURE(errorMessage));
  }
};

export const GET_USERS_BY_VACANCY_ID_SUCCESS = createAction('vacancies/GET_USERS_BY_VACANCY_ID.SUCCESS');
export const getUsersByVacancyId = (id: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/vacancy/${id}`,
    });
    dispatch(GET_USERS_BY_VACANCY_ID_SUCCESS(data));
  } catch (err) {}
};

export const CLEAR_USERS = createAction('users/CLEAR_USERS');
export const clearUsers =
  (payload = []) =>
  (dispatch: AppDispatch) => {
    dispatch(CLEAR_USERS(payload));
  };

export const UPLOAD_AVATAR_SUCCESS = createAction('users/UPLOAD_AVATAR.SUCCESS');
export const uploadAvatar = (selectedFile, userId: string) => async (dispatch: AppDispatch) => {
  try {
    const formData = new FormData();
    formData.append('avatar', selectedFile);

    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `users/avatar/${userId}`,
      query: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    dispatch(UPLOAD_AVATAR_SUCCESS(data));
  } catch (err) {}
};

export const GET_AVATAR_SUCCESS = createAction('users/GET_AVATAR.SUCCESS');
export const fetchUserAvatar = (key: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/avatar/${key}`,
    });
    dispatch(GET_AVATAR_SUCCESS(data));
  } catch (err) {}
};

export const DELETE_AVATAR_SUCCESS = createAction('users/DELETE_AVATAR.SUCCESS');
export const deleteAvatar =
  (userId: string, onSuccess: () => void, onFailure: (err: string) => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `users/avatar/${userId}`,
      });

      dispatch(DELETE_AVATAR_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      onFailure(errorMessage);
    }
  };

interface INewDocumentData {
  name: string;
  description: string;
  type: string;
  visibility: string;
  link: string;
}
export const ADD_DOCUMENT_SUCCESS = createAction('users/ADD_DOCUMENT.SUCCESS');
export const ADD_DOCUMENT_FAILURE = createAction('users/ADD_DOCUMENT.FAILURE');
export const addDocument =
  (
    newDocumentData: INewDocumentData,
    userId: string,
    newDocumentFile: File | null,
    onSuccess: () => void,
    onFailure: (err: any) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      if (newDocumentFile) {
        formData.append('document', newDocumentFile);
      } else {
        formData.append('link', newDocumentData.link);
      }
      formData.append('name', newDocumentData.name);
      formData.append('description', newDocumentData.description);
      formData.append('type', newDocumentData.type);
      formData.append('visibility', newDocumentData.visibility);
      formData.append('userId', userId);

      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'users/document',
        query: formData,
      });
      dispatch(ADD_DOCUMENT_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(ADD_DOCUMENT_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const DELETE_DOCUMENT_SUCCESS = createAction('users/DELETE_DOCUMENT.SUCCESS');
export const DELETE_DOCUMENT_FAILURE = createAction('users/DELETE_DOCUMENT.FAILURE');
export const deleteDocument =
  (documentId: string, onSuccess: () => void, onFailure: (error: string) => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `users/document/${documentId}`,
      });
      dispatch(DELETE_DOCUMENT_SUCCESS(data));

      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(DELETE_DOCUMENT_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const EDIT_DOCUMENT_SUCCESS = createAction('users/EDIT_DOCUMENT.SUCCESS');
export const EDIT_DOCUMENT_FAILURE = createAction('users/EDIT_DOCUMENT.FAILURE');
export const editDocument =
  (
    newDocumentData: { [key: string]: any },
    documentId: string,
    onSuccess: () => void,
    onFailure: (error: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `users/document/${documentId}`,
        query: newDocumentData,
      });
      dispatch(EDIT_DOCUMENT_SUCCESS(data));
      onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch(EDIT_DOCUMENT_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const acceptInvite =
  (token: string, email: string, teamName: string, onSuccess: () => void, onFailure: (error: any) => void) =>
  async () => {
    try {
      await ApiService.apiCall({
        method: 'PUT',
        endpoint: `users/accept-invite/${token}`,
        query: { email: email, teamName: teamName },
      });

      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

export const CLEAR_SELECTED_USER = createAction('users/CLEAR_SELECTED_USER');
export const clearSelectedUser = () => async (dispatch: AppDispatch) => {
  dispatch(CLEAR_SELECTED_USER());
};
