import { ReactComponent as Candidats } from '../../../svg/sidebar/candidates.svg';
import { ReactComponent as Reminders } from '../../../svg/sidebar/reminders.svg';
import { ReactComponent as RemindersLink } from '../../../svg/sidebar/reminders-link.svg';
import { ReactComponent as Vacancies } from '../../../svg/sidebar/vacancies.svg';
import { ReactComponent as Clients } from '../../../svg/sidebar/clients.svg';
import Badge from '@mui/material/Badge';
import { Tooltip } from '@mui/material';

export function getIcon(route: string) {
  switch (route) {
    case 'candidates':
      return <Candidats className={'icon'} width={24} height={24} />;
    case 'vacancies':
      return <Vacancies className={'icon'} width={36} height={36} />;
    case 'clients':
      return <Clients className={'icon'} width={24} height={24} />;
    case 'reminders':
      return <RemindersLink className={'icon'} width={24} height={24} />;
    default:
      return null;
  }
}

export function warningIcon(route: string, message: string) {
  switch (route) {
    case 'reminders-all':
      return (
        <Tooltip title={message}>
          <Badge color='secondary' overlap='circular' badgeContent=' ' variant='dot'>
            <Reminders className={'icon'} width={24} height={24} fill='#FFFFFF' />
          </Badge>
        </Tooltip>
      );
    default:
      return null;
  }
}
