import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { ApiService } from '../middleware/api';

type GetAllTagsActionType = {
  refersTo?: string;
  onFailure?: (error: string) => void;
};

export const GET_ALL_TAGS_SUCCESS = createAction('tags/GET_ALL_TAGS.SUCCESS');
export const getAllTags =
  ({ refersTo, onFailure }: GetAllTagsActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `tags`,
        qsParams: {
          refersTo: refersTo || null,
        },
      });

      dispatch(GET_ALL_TAGS_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

type GetTagActionType = {
  tagId: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const GET_TAG_SUCCESS = createAction('tags/GET_TAG.SUCCESS');
export const getTagById =
  ({ tagId, onSuccess, onFailure }: GetTagActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `tags/${tagId}`,
      });

      dispatch(GET_TAG_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

type CreateTagsActionType = {
  tagsData: {
    name: string;
    refersTo: string;
  }[];
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const CREATE_TAGS_SUCCESS = createAction('tags/CREATE_TAGS.SUCCESS');
export const createTags =
  ({ tagsData, onSuccess, onFailure }: CreateTagsActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'tags',
        query: { tags: tagsData },
      });

      dispatch(CREATE_TAGS_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

type UpdateTagActionType = {
  tagId: string;
  tagData: {
    name: string;
    refersTo: string;
  };
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const UPDATE_TAG_SUCCESS = createAction('tags/UPDATE_TAG.SUCCESS');
export const updateTag =
  ({ tagId, tagData, onSuccess, onFailure }: UpdateTagActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `tags/${tagId}`,
        query: { tag: tagData },
      });

      dispatch(UPDATE_TAG_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

type DeleteTagActionType = {
  tagId: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const DELETE_TAG_SUCCESS = createAction('tags/DELETE_TAG.SUCCESS');
export const deleteTag =
  ({ tagId, onSuccess, onFailure }: DeleteTagActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `tags/${tagId}`,
      });

      dispatch(DELETE_TAG_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };
